import React, { useState, useEffect, useRef } from 'react';
import { addDoc, collection, getDocs, query, where} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { auth, db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation } from 'react-router-dom';
import Navigation from './Navigation';
import { Navigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Webcam from 'react-webcam';
import './EntryForm.css';
import 'material-icons/css/material-icons.css';
import AudioVisualizer from './AudioVisualizer'; // Import the new DownloadView component

const EntryForm = () => {
  const [userUsernames, setUserUsernames] = useState({});
  const [displayName, setDisplayName] = useState(''); // State for displayName
  const [placeholderText, setPlaceholderText] = useState("Compose a note...");
  const [user, loading, error] = useAuthState(auth);
  const [isAssistantButtonDisabled, setIsAssistantButtonDisabled] = useState(false);
  const [username, setUsername] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [newEntrySubmitted, setNewEntrySubmitted] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const location = useLocation();
  const [suggestionText, setSuggestionText] = useState('');
  const [userCategories, setUserCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [isSuggestionRequested, setIsSuggestionRequested] = useState(false);
  const [processing, setProcessing] = useState(false); // Indicates OCR processing
  const [imgSrc, setImgSrc] = useState(null); // Captured image source
  const [isCameraAllowed, setIsCameraAllowed] = useState(window.innerWidth >= 100 && window.innerWidth < 768);
  const [drawing, setDrawing] = useState(false); // Is the user drawing?
  const [rect, setRect] = useState({}); // Coordinates of the drawn rectangle
  const [transcript, setTranscript] = useState(''); // New state to hold the ongoing transcript
  const recognitionRef = useRef(null); // Add a ref for the recognition instance
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state to manage button disabled status
  const [isDisabled, setIsDisabled] = useState(false);
  const [activeMediaStreams, setActiveMediaStreams] = useState([]);
  const [isCropping, setIsCropping] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showFullscreenModal, setShowFullscreenModal] = useState(false);
  const [isAiResponse, setIsAiResponse] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [audioData, setAudioData] = useState(new Uint8Array(0));
  const [isRecording, setIsRecording] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const lineBreakRef = useRef(null);
  const entryComponentRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [analysisResponse, setAnalysisResponse] = useState('');
  const [userPrompt, setUserPrompt] = useState('');
  const [formattedResponse, setFormattedResponse] = useState('');
  const [isSavingEnabled, setIsSavingEnabled] = useState(true);
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [activeButton, setActiveButton] = useState('Text');
  const [isRectangleDrawn, setIsRectangleDrawn] = useState(false);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [isCameraActive, setIsCameraActive] = useState(false);

  const navigate = useNavigate();

  // Use onAuthStateChanged to listen for changes in user authentication
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        // User is not authenticated, navigate to the login page or handle accordingly
        navigate('/');
      } else {
        // If the user is authenticated, fetch their displayName based on username
        fetchUserDisplayName(user.uid);
        console.log(user.uid);
        setIsLoading(false); // Set loading to false once authentication is confirmed
      }
    });

    return () => unsubscribe(); // Unsubscribe when the component unmounts
  }, [navigate]);
  


  const fetchUsername = async (uid) => {
    try {
        // Create a query to find the user document where 'UID' field matches the provided uid
        const userQuery = query(collection(db, 'users'), where('UID', '==', uid));
        const querySnapshot = await getDocs(userQuery);

        if (!querySnapshot.empty) {
            // Assuming the username is stored in the 'username' field
            const userData = querySnapshot.docs[0].data();
            setUsername(userData.username);
        }
    } catch (error) {
        console.error('Error fetching user data:', error);
    }
};

useEffect(() => {
  if (user) {
      fetchUsername(user.uid);
  }
}, [user]);

  // Use onAuthStateChanged to listen for changes in user authentication
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (!user) {
            // User is not authenticated, navigate to the login page or handle accordingly
            navigate('/');
        } else {
            // If the user is authenticated, fetch their displayName based on username
            console.log(user.uid);
            setIsLoading(false); // Set loading to false once authentication is confirmed
        }
    });

    return () => unsubscribe(); // Unsubscribe when the component unmounts
}, [navigate]);

  const [videoConstraints, setVideoConstraints] = useState({
    width: 1280,
    height: 720,
    facingMode: "environment", // Try setting as just 'environment' initially
  });
  const canvasRef = useRef(null);
  const webcamRef = useRef(null);
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
      console.log('Signed out successfully');
      console.log("User Object:", JSON.stringify(user, null, 2));
    } catch (error) {
      console.error('Sign-out error:', error);
    }
  };
    const calculateAspectRatio = () => {
      // Example for a 16:9 aspect ratio
      const aspectRatio = 16 / 9;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
    
      let newWidth, newHeight;
    
      // Calculate based on the width or height, whichever is more limiting
      if (viewportWidth / viewportHeight > aspectRatio) {
        // Limited by height
        newHeight = viewportHeight;
        newWidth = newHeight * aspectRatio;
      } else {
        // Limited by width
        newWidth = viewportWidth;
        newHeight = newWidth / aspectRatio;
      }
    
      return { width: newWidth, height: newHeight };
    };

    const initializeCanvasSize = () => {
      if (webcamRef.current && webcamRef.current.video && canvasRef.current) {
        const video = webcamRef.current.video;
        if (video.readyState === 4) { // Ensure the video is ready
          const { width, height } = calculateAspectRatio();
          canvasRef.current.width = width;
          canvasRef.current.height = height;
          canvasRef.current.style.width = `${width}px`;
          canvasRef.current.style.height = `${height}px`;
          setVideoConstraints({ ...videoConstraints, width, height });
        }
      }
    };

  const isSubmitEnabled = content.length > 0;

  const countWords = (str) => {
    return str.trim().split(/\s+/).filter(word => word.length > 0).length;
  };
  const isQuickReplyEnabled = countWords(content) >= 3;

  const saveAIResponse = async (formattedResponseFromModal) => {
    console.log('Attempting to save AI response', formattedResponse);
    if (!formattedResponse || !user) {
      console.error('No response to save or user not logged in');
      return;
    }
    setIsAiResponse(true); // Indicate that this is an AI response
    
    // Recursive function to convert React elements and their children to a string
    const elementToString = (element) => {
      // If it's a string, number, or similar, return it as is
      if (typeof element === 'string' || typeof element === 'number' || typeof element === 'boolean') {
        return String(element);
      }
  
      // If it's an array, process each element
      if (Array.isArray(element)) {
        return element.map(child => elementToString(child)).join('');
      }
  
      // If it's a React element with children, process the children
      if (element && element.props && element.props.children) {
        return elementToString(element.props.children);
      }
  
      // For any other type of object, return an empty string
      return '';
    };
  
    // Convert the formattedResponse (array of React elements) to a plain string
    // const responseString = formattedResponse.map(element => elementToString(element)).join('\n');
    const responseString = formattedResponseFromModal.map(element => elementToString(element)).join('\n');

  
    try {
      const newEntry = {
        content: responseString,  // Use the converted string for the content field
        category: 'AI Response', // predefined category
        aiResponse: "yes",
        userPrompt: userPrompt, // include the userPrompt here
        collectionName: "", // no specific collection
        order: null, // no specific order
        uid: user.uid, // user's UID from the authentication state
        timestamp: new Date(), // current timestamp
      };
  
      // Add the new entry to the 'messages' collection
      await addDoc(collection(db, 'messages'), newEntry);
  
      console.log('AI response saved successfully!');
      // Optionally, reset analysisResponse or provide user feedback
      setAnalysisResponse(''); // reset analysis response if needed
      setUserPrompt('');
      alert('Your response has been saved successfully!');
      setIsSavingEnabled(false); // Disable after saving

    } catch (error) {
      console.error('Error saving AI response:', error);
      // Optionally, provide user feedback about the error
    }
  };

  const handleQuickReply = async () => {

    const functions = getFunctions();
    const quickReply = httpsCallable(functions, 'quickReply');
    console.log("quick reply clicked");

    // Show the modal with "Processing..." immediately
    setIsSavingEnabled(false);

    setModalMessage("Thinking...");
    setShowFullscreenModal(true); // Change to setShowFullscreenModal
  
    try {
      const response = await quickReply({ prompt: content });
      console.log(content);
      
      if (response && response.data && response.data.analysis) {
        console.log("display");
        console.log(response.data.analysis);
        const formattedText = formatResponseText(response.data.analysis);
        setFormattedResponse(formattedText); // Save the formatted response to state
        setUserPrompt(content); // <-- Add this line

        // Update the modal with the actual response
        setModalMessage(formatResponseText(response.data.analysis));
        setIsSavingEnabled(true);
      } else {
        console.error('No valid response from quick reply.');
        setModalMessage("No valid response received.");
      }
    } catch (error) {
      console.error('Error in quick reply:', error);
      setModalMessage("Error in processing the request.");
    }
  };


  useEffect(() => {
    const body = document.body;
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    // Store the default content of the viewport meta tag
    const defaultContent = viewportMeta ? viewportMeta.getAttribute('content') : null;
  
    if (isCameraActive) {
      body.classList.add('no-scroll');
      // Disable pinch-zoom by setting a viewport meta tag content
      if (viewportMeta) {
        const noZoomContent = `${defaultContent}, user-scalable=no`;
        viewportMeta.setAttribute('content', noZoomContent);
      }
    } else {
      body.classList.remove('no-scroll');
      // Reset the viewport meta tag content
      if (viewportMeta && defaultContent) {
        viewportMeta.setAttribute('content', defaultContent);
      }
    }
  
    // Cleanup function to reset the changes when the component is unmounted or the effect re-runs
    return () => {
      body.classList.remove('no-scroll');
      if (viewportMeta && defaultContent) {
        viewportMeta.setAttribute('content', defaultContent);
      }
    };
  }, [isCameraActive]);
  

  useEffect(() => {
    if (isCameraActive) {
      setCanvasSize(); // Set initial canvas size when camera is activated
      console.log('Camera is active, canvas should be ready.');
    } else {
      // Call updateEntryComponentPosition when the camera is deactivated
      updateEntryComponentPosition();
    }
  }, [isCameraActive]); // Depend on isCameraActive

  useEffect(() => {
    // When the activeButton changes to 'Text', clear the rectangle and reset drawing state
    if (activeButton === 'Text') {
      setRect({});
      setDrawing(false);
      setIsCropping(false);
  
      // Optionally clear the canvas if necessary
      if (canvasRef.current) {
        const ctx = canvasRef.current.getContext('2d');
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      }
    }
  }, [activeButton]);
  

  useEffect(() => {
    const handleResize = () => {
      setIsCameraAllowed(window.innerWidth < 1440);
      initializeCanvasSize();
    };
    window.addEventListener('resize', initializeCanvasSize);
    return () => window.removeEventListener('resize', initializeCanvasSize);
  }, []);

  // Set canvas size when camera is activated
  useEffect(() => {
    if (isCameraActive) {
      initializeCanvasSize();
      console.log('Camera is active, canvas should be ready.');
    }
  }, [isCameraActive]);
  

  useEffect(() => {
    let isMounted = true;

    const getRearCamera = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
  
        const rearCamera = videoDevices.find(device => device.label.toLowerCase().includes('back'));
  
        if (rearCamera && isMounted) {
          setVideoConstraints({
            width: 1280,
            height: 720,
            deviceId: { exact: rearCamera.deviceId }
          });
        }
      } catch (error) {
        console.error("Error accessing media devices.", error);
      }
    };
  
    getRearCamera();

    return () => {
      isMounted = false;
    };
  }, []);

  const updateEntryComponentPosition = () => {
    if (entryComponentRef.current) {
      const viewportHeight = window.innerHeight;
      const entryComponentHeight = entryComponentRef.current.offsetHeight;
      const bottomPosition = 42; // 20px from the bottom
  
      entryComponentRef.current.style.position = 'fixed';
      entryComponentRef.current.style.bottom = `${bottomPosition}px`;
      entryComponentRef.current.style.left = '0';
      entryComponentRef.current.style.right = '0';
      entryComponentRef.current.style.maxHeight = `${viewportHeight - bottomPosition - entryComponentHeight}px`;
      // entryComponentRef.current.style.overflowY = 'auto'; // Add scrolling if necessary
    }
  };
  
  useEffect(() => {
    updateEntryComponentPosition();
    window.addEventListener('resize', updateEntryComponentPosition);
    return () => window.removeEventListener('resize', updateEntryComponentPosition);
  }, []);
  
  const startDrawing = (e) => {
    const pos = getPointerPos(canvasRef.current, e);
    e.preventDefault(); // Prevent default touch behavior like scrolling and zooming
    if (!canvasRef.current) return;
    if (!isCropping) return; // Add this line to check if cropping is enabled
    if (activeButton !== 'Crop') return;

    let clientX = e.clientX;
    let clientY = e.clientY;
  
    // Handle touch events
   // Use touch coordinates if it's a touch event
   if (e.type === 'touchstart' && e.touches) {
    const touch = e.touches[0];
    clientX = touch.pageX; // pageX and pageY give the coordinates relative to the <html> element
    clientY = touch.pageY;
  }
  
    const rect = canvasRef.current.getBoundingClientRect();
    const scaleX = canvasRef.current.width / rect.width;
    const scaleY = canvasRef.current.height / rect.height;
  
    setRect({
      x1: pos.x,
      y1: pos.y,
    });
    setDrawing(true);
  };
  

  useEffect(() => {
    const video = webcamRef.current && webcamRef.current.video;
  
    const onLoadedMetadata = () => {
      setCanvasSize();
      console.log('Video metadata loaded, canvas size set.');
    };
  
    if (video) {
      video.addEventListener('loadedmetadata', onLoadedMetadata);
    }
  
    return () => {
      if (video) {
        video.removeEventListener('loadedmetadata', onLoadedMetadata);
      }
    };
  }, [webcamRef, isCameraActive]);
  

  const setCanvasSize = () => {
    if (webcamRef.current && webcamRef.current.video && canvasRef.current) {
      const video = webcamRef.current.video;
      if (video.readyState === 4) {
        canvasRef.current.width = video.videoWidth;
        canvasRef.current.height = video.videoHeight;
        canvasRef.current.style.width = `${video.clientWidth}px`;
        canvasRef.current.style.height = `${video.clientHeight}px`;
        console.log('Canvas size set.');
      }
    }
  };
  
  useEffect(() => {
    window.addEventListener('resize', setCanvasSize);
    return () => window.removeEventListener('resize', setCanvasSize);
  }, [isCameraActive]);


  const draw = (e) => {
    e.preventDefault(); // Prevent default touch behavior
    if (!drawing || !canvasRef.current) return;
    if (!isCropping || !drawing) return; // Update this line
    if (processing) return; // Exit if an image is being processed


    let clientX = e.clientX;
    let clientY = e.clientY;
  
    // Handle touch events
 // Use touch coordinates if it's a touch event
    if (e.type === 'touchmove' && e.touches) {
      const touch = e.touches[0];
      clientX = touch.pageX; // pageX and pageY give the coordinates relative to the <html> element
      clientY = touch.pageY;
    }
  
    const ctx = canvasRef.current.getContext('2d');
    const rectCanvas = canvasRef.current.getBoundingClientRect();
  
    // Scaling factors
    const scaleX = canvasRef.current.width / rectCanvas.width;
    const scaleY = canvasRef.current.height / rectCanvas.height;
  
    // Calculate current position
    const x2 = (clientX - rectCanvas.left) * scaleX;
    const y2 = (clientY - rectCanvas.top) * scaleY;
  
    // Clear the canvas before each new draw
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  
    // Begin a new path for the rectangle
    ctx.beginPath();
    ctx.strokeStyle = "rgb(121, 84, 180)";
    ctx.lineWidth = 6;
  
    // Draw the rectangle
    ctx.rect(rect.x1, rect.y1, x2 - rect.x1, y2 - rect.y1);
  
    // Stroke the current path
    ctx.stroke();
  };

  // Inside a useEffect or component mount hook
  useEffect(() => {
    const canvasElement = canvasRef.current;
    if (canvasElement) {
      const action = isCropping ? 'addEventListener' : 'removeEventListener';
      canvasElement[action]('mousedown', startDrawing);
      canvasElement[action]('mousemove', draw);
      canvasElement[action]('mouseup', stopDrawing);
      canvasElement[action]('mouseleave', stopDrawing);
  
      // For touch events
      canvasElement[action]('touchstart', startDrawing);
      canvasElement[action]('touchmove', draw);
      canvasElement[action]('touchend', stopDrawing); // Corrected this line
  
      return () => {
        canvasElement.removeEventListener('mousedown', startDrawing);
        canvasElement.removeEventListener('mousemove', draw);
        canvasElement.removeEventListener('mouseup', stopDrawing);
        canvasElement.removeEventListener('mouseleave', stopDrawing);
        canvasElement.removeEventListener('touchstart', startDrawing);
        canvasElement.removeEventListener('touchmove', draw);
        canvasElement.removeEventListener('touchend', stopDrawing);
      };
    }
  }, [isCropping, activeButton]); // Dependencies


const stopDrawing = (e) => {
  e.preventDefault(); // Prevent default touch behavior
  if (!drawing) return;
  if (!isCropping || !drawing) return; // Update this line
  

  // Declare variables outside the if statement
  let clientX;
  let clientY;

  // Check for the touchend event to use the changedTouches array
  if (e.type === 'touchend' && e.changedTouches) {
    const touch = e.changedTouches[0];
    clientX = touch.pageX;
    clientY = touch.pageY;
  } else {
    // If it's not a touch event, use the mouse event coordinates
    clientX = e.clientX;
    clientY = e.clientY;
  }

  // Everything else remains the same...
  const rectCanvas = canvasRef.current.getBoundingClientRect();
  const scaleX = canvasRef.current.width / rectCanvas.width;
  const scaleY = canvasRef.current.height / rectCanvas.height;
  const x2 = (clientX - rectCanvas.left) * scaleX;
  const y2 = (clientY - rectCanvas.top) * scaleY;

  setRect({ ...rect, x2, y2 });
  setDrawing(false);
};

useEffect(() => {
  if (isRecording) {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    recognitionRef.current = new SpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.lang = 'en-US';

    let finalTranscript = '';

    recognitionRef.current.onresult = (event) => {
      let interimTranscript = '';
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        const transcriptPiece = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcriptPiece + ' ';
        } else {
          interimTranscript += transcriptPiece;
        }
      }
      setContent(finalTranscript + interimTranscript);
    };

    recognitionRef.current.onerror = (event) => {
      console.error('Speech Recognition Error:', event.error);
    };

    recognitionRef.current.start();

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
        recognitionRef.current.onresult = null;
        recognitionRef.current.onerror = null;
        recognitionRef.current = null;
      }
    };
  }
}, [isRecording]); // Removed transcript from dependencies



const stopSpeechRecognition = () => {
  if (recognitionRef.current) {
    recognitionRef.current.onresult = null;
    recognitionRef.current.onerror = null;
    recognitionRef.current.stop();
    recognitionRef.current.abort();
    recognitionRef.current = null;
  }
  setTranscript('');
  setIsRecording(false);
};

const stopMediaStreams = () => {
  console.log("Stopping Media Streams");
  activeMediaStreams.forEach(stream => {
    stream.getTracks().forEach(track => {
      console.log("Stopping track:", track);
      track.stop();
    });
  });
  setActiveMediaStreams([]);
  setMediaStream(null);
  setIsRecording(false);
};

const toggleRecording = () => {
  if (!isRecording) {
    console.log("Starting Recording");
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        setActiveMediaStreams(prevStreams => [...prevStreams, stream]);
        setMediaStream(stream);
        if (recognitionRef.current) {
          recognitionRef.current.continuous = true;
          recognitionRef.current.start();
        }
        setIsRecording(true);
      })
      .catch(error => {
        console.error("Error starting speech recognition: ", error);
      });
  } else {
    console.log("Stopping Recording");
    stopSpeechRecognition();
    stopMediaStreams();
    setIsRecording(false);
  }
};

useEffect(() => {
  return () => {
    console.log("Component Unmounting - Cleaning Up");
    stopSpeechRecognition();
    stopMediaStreams();
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      recognitionRef.current = null;
    }
    stopMediaStreams();
  };
}, []);

const handleCaptureError = () => {
  setModalMessage("Please make sure an image is captured and a crop area is selected.");
  setShowModal(true);
};

const CustomModal = ({ message, onClose }) => (
  <div id="cameraWrapper">
  <div id="modalContentWrapper" className="modal-content" style={{borderRadius: '6px'}}>
    <div id="modalCopy">
      <p>{message}</p>
    </div>
    <div className="modal-footer">
      <button id="yes" onClick={onClose}>Dismiss</button>
    </div>
  </div>
  </div>
);

function formatResponseText(text) {
  // Replace newline characters with <br /> tags
  return text.split('\n').map((item, key) => {
    return (
      <span key={key}>
        {item}
        <br/>
      </span>
    );
  });
}

const FullscreenModal = ({ message, saveAIResponse, onClose, isSavingEnabled}) => (
  <div style={{
    position: 'fixed', // Fullscreen and fixed
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent background
    display: 'flex',
    flexDirection: 'column', // Align children vertically
    justifyContent: 'center', // Center children vertically in container
    alignItems: 'center', // Center children horizontally in container
    zIndex: 1000, // High z-index to overlay everything
  }}>
    <div className="modal-content-fw" style={{
      width: '100%', // Take full width of the screen
      backgroundColor: 'rgb(20,20,20)', // Modal background color
      position: 'relative', // For positioning the close icon
      height: '100vh',
      padding: '24px',
      display: 'flex',
      flexDirection: 'column', // Align children vertically
      overflowY: 'auto', // Allow scrolling for overflow content
      boxSizing: 'border-box', // Include padding in width calculation
    }}>
      <p style={{
        textAlign: 'justify',
        lineHeight: '1.6rem',
        marginBottom: '16px', // Add space between paragraph and button
        marginTop: '36px', // Add space between paragraph and button

      }}>{message}</p>
      <button 
          disabled={!isSavingEnabled} // Disable the button based on isSavingEnabled state
          onClick={() => saveAIResponse(formattedResponse)} // Call saveAIResponse with formatted response
          style={{ 
          backgroundColor: 'rgb(121, 84, 180)', 
          color: '#fff', 
          width: '200px',
          padding: '10px 15px', 
          border: 'none', 
          borderRadius: '5px', 
          cursor: 'pointer',
          marginBottom: '128px',
          cursor: isSavingEnabled ? 'pointer' : 'default', // Change cursor based on isSavingEnabled state
          opacity: isSavingEnabled ? 1 : 0.5, // Dim the button if disabled 
        }}>
        Save Response
      </button>
      <div
        onClick={onClose}
        style={{
          position: 'absolute', // Position it relative to the modal content
          top: '24px', // Align with padding
          right: '24px', // Align with padding
          cursor: 'pointer',
          fontSize: '24px',
          color: '#fff',
        }}
      >
        <i className="material-icons">close</i> {/* Assuming you are using Material Icons */}
      </div>
    </div>
  </div>
);


const handleTouchMove = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

useEffect(() => {
  const dropdownMenu = document.querySelector('.dropdown-menu');
  if (dropdownMenu) {
    dropdownMenu.addEventListener('touchmove', handleTouchMove, { passive: false });
  }
  return () => {
    if (dropdownMenu) {
      dropdownMenu.removeEventListener('touchmove', handleTouchMove);
    }
  };
}, []);

useEffect(() => {
  if (dropdownOpen) {
    // Prevent scrolling on the body when dropdown is open
    document.body.style.overflow = 'hidden';
  } else {
    // Re-enable scrolling when dropdown is closed
    document.body.style.overflow = 'auto';
  }

  // Cleanup function to reset the style when the component unmounts
  return () => {
    document.body.style.overflow = 'auto';
  };
}, [dropdownOpen]);

const applyCropAndProcessImage = async () => {
  const imageSrc = webcamRef.current.getScreenshot();
  if (imageSrc && rect.x2 && rect.y2) { // Ensure there's an image and a crop rectangle
    const img = new Image();
    img.src = imageSrc;

    img.onload = () => {
      const offscreenCanvas = document.createElement('canvas');
      const ctx = offscreenCanvas.getContext('2d');

      const scaleX = img.naturalWidth / webcamRef.current.video.videoWidth;
      const scaleY = img.naturalHeight / webcamRef.current.video.videoHeight;

      // Adjust the coordinates and dimensions for the cropped area
      const xStart = Math.min(rect.x1, rect.x2) * scaleX;
      const yStart = Math.min(rect.y1, rect.y2) * scaleY;
      const width = Math.abs(rect.x2 - rect.x1) * scaleX;
      const height = Math.abs(rect.y2 - rect.y1) * scaleY;

      // Set the dimensions of the off-screen canvas
      offscreenCanvas.width = width;
      offscreenCanvas.height = height;

      // Draw the (cropped) image to the off-screen canvas
      ctx.drawImage(img, xStart, yStart, width, height, 0, 0, width, height);

      // Convert the cropped area to a base64 image
      const processedImageData = offscreenCanvas.toDataURL('image/jpeg');
      setCroppedImageUrl(processedImageData); // Optionally display the cropped image

      // Send the processed (cropped) image for OCR processing
      sendForOCRProcessing(processedImageData);
      setRect({}); // Reset rectangle state
      setDrawing(false); // Reset drawing state
      setIsCropping(false);
      setDrawing(false);
    };
  } else {
    handleCaptureError();
  }
};

useEffect(() => {
  setIsCropping(activeButton === 'Crop');
}, [activeButton]);

const captureAndProcessImage = async () => {
  const imageSrc = webcamRef.current.getScreenshot();
  setProcessing(true); // Set processing to true when capture starts
  setIsCropping(false);
  setDrawing(false);
  if (imageSrc) {
    const img = new Image();
    img.src = imageSrc;

    img.onload = () => {
      const offscreenCanvas = document.createElement('canvas');
      const ctx = offscreenCanvas.getContext('2d');

      let xStart = 0;
      let yStart = 0;
      let width = img.naturalWidth;
      let height = img.naturalHeight;

      // Check if the user has drawn a rectangle
      if (rect.x2 && rect.y2 && isCropping) {
        const scaleX = img.naturalWidth / webcamRef.current.video.videoWidth;
        const scaleY = img.naturalHeight / webcamRef.current.video.videoHeight;

        // Adjust the coordinates and dimensions for the cropped area
        xStart = Math.min(rect.x1, rect.x2) * scaleX;
        yStart = Math.min(rect.y1, rect.y2) * scaleY;
        width = Math.abs(rect.x2 - rect.x1) * scaleX;
        height = Math.abs(rect.y2 - rect.y1) * scaleY;
      }

      // Set the dimensions of the off-screen canvas
      offscreenCanvas.width = width;
      offscreenCanvas.height = height;

      // Draw the (cropped) image to the off-screen canvas
      ctx.drawImage(img, xStart, yStart, width, height, 0, 0, width, height);

      // Convert the cropped area to a base64 image
      const processedImageData = offscreenCanvas.toDataURL('image/jpeg');
      setCroppedImageUrl(processedImageData); // Optionally display the cropped image

      // Send the processed (cropped) image for OCR processing
      sendForOCRProcessing(processedImageData);
      // setProcessing(false); // End processing
    };
  } else {
    alert("No image captured from the camera.");
  }
};

  
  const sendForOCRProcessing = async (base64Image) => {
    setProcessing(true);
    try {
      // Remove the Data URL prefix if present
      const base64Data = base64Image.replace(/^data:image\/(png|jpeg);base64,/, '');
  
      const functions = getFunctions();
      const processImage = httpsCallable(functions, 'processImage');
      const { data } = await processImage({ image: base64Data }); // Use the cleaned-up base64 data
      setContent(data.detectedText);
    } catch (error) {
      console.error('Error processing image:', error);
      alert("Error processing image");
    } finally {
      setProcessing(false);
      setIsCameraActive(false);
      setIsCropping(false); // Add this line to reset cropping state
      setRect({}); // Reset rectangle state
      setDrawing(false); // Reset drawing state
      updateEntryComponentPosition();
    }
  };

  const handleDropdownSelect = (selectedCategory) => {
    // Set the category to the selected category
    setCategory(selectedCategory);
  };

  const fetchUserDisplayName = async (username) => {
    try {
      // Create a query to find the user document where 'username' field matches the provided username
      const usersQuery = query(collection(db, 'users'), where('UID', '==', username));
  
      // Get the documents that match the query (should be a single document)
      const usersSnapshot = await getDocs(usersQuery);
  
      if (!usersSnapshot.empty) {
        // Get the user document data
        const userDocData = usersSnapshot.docs[0].data();
        console.log("User document data:", userDocData);
        console.log("Username:", userDocData.username);
        setDisplayName(userDocData.username);
      } else {
        console.log("User document does not exist");
      }
    } catch (error) {
      console.error('Error fetching user document:', error);
    }
  };

  const handleKeyDown = (e) => {
    // Check if the Tab key was pressed and there is suggestion text available
    if (e.key === 'Tab' && suggestionText) {
      e.preventDefault(); // Prevent the default tab behavior
      setContent(suggestionText); // Set the content to the suggestion text
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isSuggestionRequested) {
      if (!isAiResponse && (category === '' || (category === 'New Category' && newCategory.trim() === ''))) {
        alert('Please select or enter a category before submitting.');
        return;
      }
    }
  
    // Trim newCategory to ensure there's no leading or trailing whitespace
    const trimmedNewCategory = newCategory.trim();
  
   // If this submission is an AI response, skip the category check.
 
    // Check if category is selected or new category is entered
    if (!isAiResponse && (category === '' || (category === 'New Category' && trimmedNewCategory === ''))) {
      alert('Please select or enter a category before submitting.');
      return;
    }
  
  
    if (category === 'New Category' && trimmedNewCategory !== '') {
      // If "New Category" is selected, use the value from the input field as the category
      setCategory(trimmedNewCategory);
    }
  
    const messagesCollection = collection(db, 'messages');
  
    try {
      await addDoc(messagesCollection, {
        content,
        category: category === 'New Category' ? trimmedNewCategory : category,
        uid: user.uid,
        username,
        timestamp: new Date(),
        order: null,
      });
  
      // Show the success message
      setShowSuccessMessage(true);
      setIsAiResponse(false); // Reset after form submission
      setIsAssistantButtonDisabled(false);

      // Hide the success message after a delay (e.g., 3 seconds)
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 2000);
  
      // onEntrySubmit();
      setNewEntrySubmitted(true);
      await fetchUserCategories();
      setSuggestionText("Compose a note..."); // Resetting the suggestion text to empty
      setContent('');
      setCategory('');
      setNewCategory(''); // Clear the newCategory value after submitting
      console.log("should be null");
    } catch (error) {
      console.error('Error adding document: ', error);
      setIsAssistantButtonDisabled(false);
    }
  };

  const fetchUserCategories = async () => {
    if (!user) {
      console.log("User not logged in, skipping category fetch");
      return;
    }
  
    try {
      const userCategoriesQuery = query(collection(db, 'messages'), where('uid', '==', user.uid));
      const userCategoriesSnapshot = await getDocs(userCategoriesQuery);
      
      const categories = userCategoriesSnapshot.docs.map(doc => doc.data().category);
      const uniqueCategories = Array.from(new Set(categories));
      setUserCategories(uniqueCategories);
    } catch (error) {
      console.error('Error fetching user categories:', error);
    }
  };
  
  useEffect(() => {
    fetchUserCategories();
  }, [user]); // Fetch categories when the user state changes



  const getPointerPos = (canvasDom, event) => {
    const rect = canvasDom.getBoundingClientRect();
    // Handle touch events
    if (event.touches) {
      return {
        x: (event.touches[0].clientX - rect.left) * (canvasDom.width / rect.width),
        y: (event.touches[0].clientY - rect.top) * (canvasDom.height / rect.height)
      };
    }
    // Handle mouse events
    return {
      x: (event.clientX - rect.left) * (canvasDom.width / rect.width),
      y: (event.clientY - rect.top) * (canvasDom.height / rect.height)
    };
  }

  const updateSuccessMessagePosition = () => {
    if (lineBreakRef.current) {
      const lineBreakPosition = lineBreakRef.current.getBoundingClientRect().top;
      const successContainer = document.querySelector('.success-button-container');
      if (successContainer) {
        successContainer.style.bottom = `${window.innerHeight - lineBreakPosition + 0}px`;
      }
    }
  };
  
  useEffect(() => {
    updateSuccessMessagePosition();
    window.addEventListener('resize', updateSuccessMessagePosition);
    return () => window.removeEventListener('resize', updateSuccessMessagePosition);
  }, []);
  

  const getTopCategories = () => {
    const categoryCounts = {};
    userCategories.forEach((category) => {
      categoryCounts[category] = (categoryCounts[category] || 0) + 1;
    });

    const sortedCategories = Object.keys(categoryCounts).sort((a, b) => categoryCounts[b] - categoryCounts[a]);
    return sortedCategories.slice(0, 3);
  };

  const topCategories = getTopCategories();

  const handleContentChange = (e) => {
    const inputText = e.target.value;
    setContent(inputText);
  
    // Disable the Assistant button if there is at least one character in the textarea
    setIsAssistantButtonDisabled(inputText.length > 0);
  };

  const TextDescription = ({ activeButton }) => {
    if (activeButton === 'Crop') {
      return <div style={{
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        color: 'white',
        padding: '4px',
        borderRadius: '5px',
        textAlign: 'center',
        margin: '10px 0'
      }}>
        Draw an area to crop then tap shutter
      </div>;
    }else{
      return <div style={{
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        color: 'white',
        padding: '4px',
        borderRadius: '5px',
        textAlign: 'center',
        margin: '10px 0'
      }}>
        Take a photo of words to copy
      </div>;
    }
  };
  
  const activateCamera = () => {
    setIsCameraActive(true);
    // Reset the drawing state and the cropped image URL when the camera is activated
    setDrawing(false);
    setRect({});
    setCroppedImageUrl(null);
    setIsCropping(false); // Ensure cropping is not active when re-activating the camera
  };

  const deactivateCamera = () => {
    updateEntryComponentPosition();
    setIsCameraActive(false);
    setIsCropping(false); // Ensure cropping is not active when deactivating the camera
  };

  const handleGeneratePrompt = async () => {
    setIsAssistantButtonDisabled(true); // Disable the button when clicked
  
    const functions = getFunctions();
    const generateJournalPrompt = httpsCallable(functions, 'generateJournalPrompt');
  
    try {
      const response = await generateJournalPrompt();
      if (response && response.data && response.data.prompt) {
        const suggestion = response.data.prompt.replace(/['"]+/g, '');
        setSuggestionText(suggestion);
      } else {
        console.error('No valid prompt response.');
      }
    } catch (error) {
      console.error('Error generating prompt:', error);
    } finally {
      setIsAssistantButtonDisabled(false); // Re-enable the button after receiving the response or in case of error
    }
  };

  return (
    loading ? <div></div> : (
      user ? (
    <>
    <Navigation user={user} handleLogout={handleLogout} />

    {isCameraActive && (
      <div className="camera-container" style={{ position: 'relative', maxWidth: '1280px', maxHeight: '720px', margin: 'auto' }}>
    {showModal && <CustomModal message={modalMessage} onClose={() => setShowModal(false)} />}
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          style={{ width: '100%', borderBottomLeftRadius: '2%', borderBottomRightRadius: '2%',  height: 'calc(75vh - env(safe-area-inset-bottom))', objectFit: 'cover'}}
          onUserMedia={setCanvasSize}
        />
        <canvas
          ref={canvasRef}
          style={{ position: 'absolute', top: 0, left: 0, cursor: (isCropping && !processing) ? 'crosshair' : 'default'}}
          onMouseDown={(isCropping && !processing) ? startDrawing : null}
          onMouseMove={(isCropping && !processing) ? draw : null}
          onMouseUp={(isCropping && !processing) ? stopDrawing : null}
          onMouseLeave={(isCropping && !processing) ? stopDrawing : null}
          onTouchStart={(isCropping && !processing) ? startDrawing : null}
          onTouchMove={(isCropping && !processing) ? draw : null}
          onTouchEnd={(isCropping && !processing) ? stopDrawing : null}
        />
         <div
            className="close"
            onClick={deactivateCamera}
            style={{
              position: 'absolute', // Adjusted for absolute positioning
              top: '0px',
              left: '-8px', // Moved to the left
              backgroundColor: 'transparent', // Semi-transparent background
              cursor: 'pointer',
              fontSize: '24px',
              color: 'rgb(255,255,255)', // Yellow color for the icon
              textAlign: 'center',
              borderRadius: '50%', // Optional: Makes it circular
              padding: '5px' // Optional: Adds some padding around the icon
            }}
          >
            <i className="material-icons" style={{fontSize: '42px !important'}}>close</i>
          </div>
  
        {processing && <div className="loader"></div>}
        <div style={{ position: 'absolute', width: '100%', height: '150px', bottom: '8px', display: 'flex', flexDirection: 'row',  justifyContent: 'center', alignItems: 'center',}}>
        {!isCropping && (
          <>
          <div style={{marginTop:'0px'}}>
          <TextDescription activeButton={activeButton} />
          {isCameraActive && activeButton === 'Text' && (
  <div style={{ height: '68px', bottom: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{bottom: '8px', width:'67px', height: '67px', backgroundColor:'transparent',  borderRadius:'100px', border: '2px solid #F8F8F8', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <div
      className="panorama_fish_eye"
      onClick={captureAndProcessImage} disabled={processing}
      style={{
        float: 'right',
        top: '8px',
        backgroundColor: isDisabled ? 'rgb(158, 134, 0)' : '#F8F8F8',
        cursor: 'pointer',
        fontSize: '24px',
        color: 'rgb(130,130,130)',
        textAlign: 'center'
      }}
    >
      <i className="material-icons">article</i>
    </div>
    </div>
  </div>
)}

{isCameraActive && activeButton === 'Crop' && (
  
  <div style={{ height: '68px', bottom: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    
    <div style={{bottom: '8px', width:'67px', height: '67px', backgroundColor:'transparent',  borderRadius:'100px', border: '2px solid #F8F8F8', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <div
      className="crop"
      onClick={applyCropAndProcessImage} 
      style={{
        float: 'right',
        top: '8px',
        backgroundColor: isDisabled ? 'rgb(158, 134, 0)' : '#F8F8F8',
        cursor: 'pointer',
        fontSize: '24px',
        color: 'rgb(130,130,130)',
        textAlign: 'center'
      }}
    >
      <i className="material-icons">crop</i>
    </div>
  </div>
  </div>
)}
            </div>
               
          </>
          
        )}
      </div>
      {isCropping && (
        <div style={{ position: 'absolute', width: '100%', height: '150px', bottom: '8px', display: 'flex', flexDirection: 'column',  justifyContent: 'center', alignItems: 'center',}}>
        {/* <button className="btn btn-primary" onClick={cancelCrop} style={{ padding: '4px', marginTop: '14px', marginLeft: '14px', borderColor: 'rgb(30,30,30)', color: 'rgb(30,30,30)', backgroundColor:'transparent', minWidth: '100px', marginRight: '10px' }}>
            Cancel crop
          </button> */}
          {/* <button className="btn btn-primary" onClick={applyCropAndProcessImage} style={{ borderColor: 'rgb(121, 84, 180)', backgroundColor:'rgb(121, 84, 180)', padding: '4px', minWidth: '100px' }}>
            Capture
          </button> */}

{activeButton === 'Crop' && (
      <>
        <div style={{
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          color: 'white',
          padding: '4px',
          borderRadius: '5px',
          textAlign: 'center',
          margin: '10px 0'
        }}>
          Draw an area to crop then tap shutter
        </div>

        <div style={{ height: '68px', bottom: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ bottom: '8px', width: '67px', height: '67px', backgroundColor: 'transparent', borderRadius: '100px', border: '2px solid #F8F8F8', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div
              className="crop"
              onClick={applyCropAndProcessImage}
              style={{
                float: 'right',
                top: '8px',
                backgroundColor: isDisabled ? 'rgb(158, 134, 0)' : '#F8F8F8',
                cursor: 'pointer',
                fontSize: '24px',
                color: 'rgb(130,130,130)',
                textAlign: 'center'
              }}
            >
              <i className="material-icons">crop</i>
            </div>
          </div>
        </div>
      </>
    )}
          
        </div>
      )}

    </div>
  )}
        {isCameraActive && (
      <div className="button-container" style={{height: '40px', marginTop: '4px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
           
      <div
          className="enable-text"
          style={{
              marginRight: '6px',
              backgroundColor: activeButton === 'Text' ? '#53378A' : 'transparent',
              color: activeButton === 'Text' ? 'white' : 'inherit',
              padding: '2px 12px',
              borderRadius: '100px',
              cursor: 'pointer'
          }}
          onClick={() => setActiveButton('Text')}
      >
          Text
      </div>
      <div
          className="enable-crop"
          style={{
              backgroundColor: activeButton === 'Crop' ? '#53378A' : 'transparent',
              color: activeButton === 'Crop' ? 'white' : 'inherit',
              padding: '2px 12px',
              borderRadius: '100px',
              cursor: 'pointer'
          }}
          onClick={() => setActiveButton('Crop')}
      >
          Crop
      </div>
  </div>
  
    )}
    
    <div className="entry-form p-3" style={{ backgroundColor: 'rgb(20,20,20)' }}>
                    {/* {croppedImageUrl && (
      <div>
        <h3>Cropped Image Preview:</h3>
        <img src={croppedImageUrl} alt="Cropped" style={{ maxWidth: '100%' }} />
      </div>
    )} */}
   
    
     
      {!isCameraActive && (
        <form onSubmit={handleSubmit}>
          {showFullscreenModal && (
            <FullscreenModal message={modalMessage}   isSavingEnabled={isSavingEnabled} formattedResponse={formattedResponse} saveAIResponse={saveAIResponse} // Passing the function as a prop
            onClose={() => setShowFullscreenModal(false)} />
          )}
          <div className="" id="entry" style={{}}>
            </div>
          <div className="mb-3" id="invisible-form" style={{marginLeft:'-16px'}}>
            
          <textarea
            id="content"
            className="form-control"
            onKeyDown={handleKeyDown} // Add this line
            value={content}
            onChange={handleContentChange}
            required
            rows="4"
            placeholder={isRecording ? transcript : (suggestionText || "Compose a note...")}            
            style={{ resize: 'none', marginTop: '8px', fontSize: '18px', outline: 'none', color: 'rgb(245,245,245)' }}
          />
            <div className="mb-3" id="entryComponent" ref={entryComponentRef} style={{ marginRight: '12px', display: 'flex', flexDirection: 'column' }}>  
               {!isCameraActive && (
        <div style={{display:'flex', flexDirection: 'row', bottom: '100px', 
      }}>
        <div className="well">
        <div onClick={handleGeneratePrompt} 
        className="suggestion" 
        style={{ cursor: isAssistantButtonDisabled ? 'not-allowed' : 'pointer', backgroundColor: isAssistantButtonDisabled ? 'rgb(192, 192, 192)' : 'rgb(192, 192, 192)'  }}>
        <i className="material-icons" style={{}}>assistant</i>
        </div>
          <div
            className="microphone-icon"
            onClick={!isButtonDisabled ? toggleRecording : undefined}
            style={{
              float: 'right',
              cursor: 'pointer',
              fontSize: '24px',
              color: isRecording || isDisabled ? 'rgb(196, 30, 58)' : 'rgb(30,30,30)',
              textAlign: 'center'
            }}
          >
            <i className="material-icons">
              {isRecording ? 'mic_off' : 'mic_none'}
            </i>
          </div>
          {/* {isCameraAllowed && ( */}
            <div
              className="camera-icon"
              onClick={!isDisabled ? activateCamera : undefined}
              style={{
                float: 'right',
                backgroundColor: isDisabled ? 'rgb(158, 134, 0)' : 'rgb(192, 192, 192)',
                cursor: isDisabled ? 'default' : 'pointer',
                fontSize: '24px',
                color: 'rgb(30,30,30)',
                textAlign: 'center',
              }}
            >
              <i className="material-icons">camera_enhance</i>
            </div>
          {/* )} */}
          <div
            className="quick-reply"
            onClick={isQuickReplyEnabled ? handleQuickReply : undefined}
              style={{
                float: 'right',
                cursor: isQuickReplyEnabled ? 'pointer' : 'not-allowed',
                fontSize: '24px',
                color: 'rgb(30,30,30)',
                textAlign: 'center',
                backgroundColor: isQuickReplyEnabled ? 'rgb(192, 192, 192)' : 'rgb(122, 122, 122)' // Darker yellow when disabled
              }}
          >
            <i className="material-icons">
              quickreply
            </i>
          </div>
        </div>
        
        {isRecording && (
            <div style={{ 
              marginLeft: '12px', // Separation from the well
              height: '40px', // Adjust as needed
            }}>
              <AudioVisualizer 
                audioData={audioData} 
                setAudioData={setAudioData} 
                isRecording={isRecording} 
              />
            </div>
            
                )}
                
        </div>

      )}
      
      
      
      <div className="line-break" ref={lineBreakRef} style={{ marginLeft: '12px', borderBottom: '2px solid rgb(53, 53, 53)'}}></div>
      <div className="submitContainer" style={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px', paddingBottom: '8px' }}>
                  {/* Dropdown and new category input field */}
                  <div className="form-row" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px' }}>
                  <div style={{ flex: 1, marginRight: 'auto' }}>
                  <div className="entry-actions-container" style={{ position: 'relative', marginTop: '-4px', height: '40px' }}>
                  <Dropdown className="categoryDropdown" onSelect={handleDropdownSelect} onToggle={(isOpen) => setDropdownOpen(isOpen)}>
                    <Dropdown.Toggle id="category-dropdown" variant="secondary" style={{ backgroundColor: 'rgb(50, 50, 50)', borderColor: 'rgb(30,30,30)', marginLeft: '16px' }}>
                {category === '' ? 'Category' : category}
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <Dropdown.ItemText style={{ color: 'rgb(255,255,255)', fontWeight: '400' }}>Create New Category</Dropdown.ItemText>
                  <Dropdown.Item key="New Category" eventKey="New Category" style={{ color: 'rgb(255,255,255)', fontWeight: '200' }}>New Category</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.ItemText style={{ color: 'rgb(255,255,255)', fontWeight: '400' }}>Top Categories</Dropdown.ItemText>
                  {topCategories.map(topCategory => (
                    <Dropdown.Item key={topCategory} eventKey={topCategory} style={{ color: 'rgb(255,255,255)', fontWeight: '200' }}>{topCategory}</Dropdown.Item>
                  ))}
                  <Dropdown.Divider />
                  <Dropdown.ItemText style={{ color: 'rgb(255,255,255)', fontWeight: '400' }}>Other Categories</Dropdown.ItemText>
                  {userCategories.map(userCategory => (
                    <Dropdown.Item key={userCategory} eventKey={userCategory} style={{ color: 'rgb(255,255,255)', fontWeight: '200' }}>{userCategory}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {category === 'New Category' && (
              <input
                type="text"
                id="newCategory"
                className="form-control"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                placeholder="Enter new category"
                style={{ marginTop: '8px', marginLeft: '13px', width: '240px', fontSize: '18px', color: 'rgb(245,245,245)' }}
              />
            )}
          </div>
          <div>
          <button
            id="submit"
            disabled={!isSubmitEnabled}
            type="submit"
            className="btn btn-primary"
            style={{
              position: 'absolute',
              bottom: '8px',
              right: '0',
              borderRadius: '100px',
              backgroundColor: isSubmitEnabled ? 'rgb(40, 40, 40)' : 'grey',
              borderColor: isSubmitEnabled ? 'rgb(60, 60, 60)' : 'grey',
              cursor: isSubmitEnabled ? 'pointer' : 'not-allowed'
            }}
          >
            Submit
          </button>
            </div>
            </div>
            </div>
            </div>
            </div>
          </div>
          <div className="success-button-container" style={{ 
              position: 'fixed', // Added position absolute
              display: 'flex', 
              marginBottom: '12vh',
              marginLeft: '-6px',
              justifyContent: 'flex-start', // Align items to the left
              alignItems: 'center', // Vertically center items in the container
            }}>
            {showSuccessMessage && (
              <div className="alert alert-success d-flex align-items-center" role="alert" style={{ borderRadius: '8px', backgroundColor: 'rgb(60, 60, 60)', borderColor: 'rgb(53,53,53)', height: '48px',  marginBottom: '10px'  }}>
                <i className="material-icons" style={{ color: 'rgb(166, 95, 248)', marginRight: '12px', fontSize: '24px', display: 'flex', alignItems: 'center' }}>check_circle</i>
                <span style={{ color: 'rgb(245, 245, 245)', fontWeight: '500', fontSize: '16px', display: 'flex-end', alignItems: 'center',}}>Note logged</span>
              </div>
            )}
          </div>
          
        </form>
      )}

    </div>
   
    </>
    ) : (
      // Redirect to login page if not authenticated
      <Navigate to="/" replace />
    )
    )
);
  }
  

export default EntryForm;