import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useAuthState } from 'react-firebase-hooks/auth';
import { deleteDoc, collection, onSnapshot, addDoc, getDoc, doc, updateDoc, getDocs, query, where, orderBy, writeBatch } from 'firebase/firestore';
import { auth, db } from '../firebase'; // Make sure to import your Firestore instance
import './CollectionView.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import Navigation from './Navigation';
import { signOut } from 'firebase/auth';

const CollectionView = () => {
    const [showModal, setShowModal] = useState(false);
    const [collections, setCollections] = useState([]);
    const [newCollectionName, setNewCollectionName] = useState('');
    const [user] = useAuthState(auth);
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [analysisResponse, setAnalysisResponse] = useState('');
    const [userPrompt, setUserPrompt] = useState('');
    const [selectedEntries, setSelectedEntries] = useState({});
    const [entries, setEntries] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editingCollectionId, setEditingCollectionId] = useState(null);
    const [editingCollectionName, setEditingCollectionName] = useState('');
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [collectionToDelete, setCollectionToDelete] = useState(null);
    const [showCollectionModal, setShowCollectionModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedCollectionName, setSelectedCollectionName] = useState(null);
    const [formattedResponse, setFormattedResponse] = useState('');
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [showHallucinateButton, setShowHallucinateButton] = useState(false);
    const [showComplexQueryUI, setShowComplexQueryUI] = useState(true);
    const [isCollectionPublic, setIsCollectionPublic] = useState(false); // Default to private
    const [chatHistory, setChatHistory] = useState([]);
    const [buttonTexts, setButtonTexts] = useState(['Loading...', 'Loading...', 'Loading...']); // Initial state with placeholders
    const [showMessages, setShowMessages] = useState(false); // State to control the visibility of messages
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [refreshTrigger, setRefreshTrigger] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const messagesEndRef = useRef(null); // Adding ref for automatic scrolling
    const navigate = useNavigate();

    const handleShowDeleteModal = () => setShowModal(true);
    const handleCloseDeleteModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleEllipsisClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        scrollToBottom(); // This will be called on component mount and after every chat history update
    }, [chatHistory]); // Dependency array includes chatHistory, so it triggers on update

    const buttonStyle = {
        display: 'block',
        margin: '16px 16px',
        padding: '8px 0px',
        border: 'none',
        cursor: 'pointer',
        backgroundColor: 'transparent',
    };

    const activeStyle = {
        ...buttonStyle,
        color: '#fff',
        borderBottom: '2px solid white' // White underline for active state
    };

    const inactiveStyle = {
        ...buttonStyle,
        color: 'darkgrey', // Dark grey color for inactive state
        borderBottom: '2px solid rgb(38, 38, 38)' // White underline for active state
    };

    useEffect(() => {
        handleGenerateQueryPrompts();
    }, []);

    const CollectionCard = ({ collection, onClick, onTogglePrivacy, isLoading }) => {
        return (
            <div className="cosine-sim-card card m-2"
                style={{ backgroundColor: 'rgb(53, 53, 53)', color: 'rgb(245, 245, 245)', cursor: isLoading ? 'not-allowed' : 'pointer' }}
                onClick={() => !isLoading && onClick(collection)}
            >
                <div className="card-body">
                    {(!collection.originalCreatorUID || collection.originalCreatorUID === user.uid) && (
                        <i
                            className="material-icons icon"
                            style={{ color: 'rgb(200,200,200)', marginBottom: '16px', cursor: isLoading ? 'not-allowed' : 'pointer' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                !isLoading && onTogglePrivacy(collection.id, collection.isPublic);
                            }}
                        >
                            {collection.isPublic ? 'lock_open' : 'lock'}
                        </i>
                    )}
                    <h5 className="card-title">{collection.name}</h5>
                    {collection.collaborators && collection.collaborators.length > 0 && (
                        <p>{`${collection.collaborators.length} subscriber${collection.collaborators.length > 1 ? 's' : ''}`}</p>
                    )}
                    {collection.originalCreatorUID && collection.originalCreatorUID !== user.uid && (
                        <>
                            <p>Created by: {collection.creatorUsername}</p>
                            {/* Small lock icon for collaborators */}
                            <div style={{ position: 'absolute', bottom: '10px', left: '10px', color: collection.isPublic ? '#43a047' : '#e53935' }}>
                                <i className="material-icons" style={{ fontSize: '16px' }}>
                                    {collection.isPublic ? 'lock_open' : 'lock'}
                                </i>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    };

    const handleGenerateQueryPrompts = async () => {
        const functions = getFunctions();
        const generateQueryPrompts = httpsCallable(functions, 'generateQueryPrompts');

        try {
            const response = await generateQueryPrompts();
            if (response && response.data && response.data.prompts) {
                // Using regex to replace non-alphanumeric characters except spaces
                setButtonTexts(response.data.prompts.map(text =>
                    text ? text.trim().replace(/[^a-zA-Z ]/g, '') : "No prompt available"
                ));
            } else {
                console.error('No valid prompts response.');
                setButtonTexts(['No prompt available', 'No prompt available', 'No prompt available']);
            }
        } catch (error) {
            console.error('Error generating prompts:', error);
            setButtonTexts(['Error fetching prompt', 'Error fetching prompt', 'Error fetching prompt']);
        }
    };

    const handleSendMessage = async (userInput) => {
        if (!userInput || !userInput.trim()) return;

        // Add user message to chat history
        const newUserMessage = { type: 'user', text: userInput };
        setChatHistory(prev => [...prev, newUserMessage]);

        // Automatically open the message div if it's closed when a new message is entered
        if (!showMessages) {
            setShowMessages(true);
        }

        // Send message to backend
        setIsAnalyzing(true);
        try {
            const functions = getFunctions();
            const queryAllFunc = httpsCallable(functions, 'queryAll');
            const response = await queryAllFunc({ prompt: userInput });
            const systemResponse = { type: 'system', text: response.data.analysis };

            // Add system response to chat history
            setChatHistory(prev => [...prev, systemResponse]);
        } catch (error) {
            console.error('Error querying all entries:', error);
            setChatHistory(prev => [...prev, { type: 'system', text: 'Failed to analyze entries. Please try again.' }]);
        }
        setIsAnalyzing(false);
    };

    const handleHallucinateClick = () => {
        setShowHallucinateButton(true);
        setShowComplexQueryUI(false); // Hide the complex query UI if it's showing
        setAnalysisResponse(''); // reset analysis response if needed
        setUserPrompt('');
    };

    const handleComplexQueryClick = () => {
        setShowComplexQueryUI(true);
        setShowHallucinateButton(false); // Hide the hallucinate button if it's showing
        setAnalysisResponse(''); // reset analysis response if needed
        setUserPrompt('');
    };

    useEffect(() => {
        if (!user) {
            navigate('/');
            return;
        }
    }, [user, navigate]);

    useEffect(() => {
        if (!selectedCollection) {
            console.log("No collection selected.");
            setEntries([]); // Clear any entries as no collection is selected
            return;
        }

        console.log("Setting up subscription for:", selectedCollection);

        const q = query(
            collection(db, 'messages'),
            where('collectionName', '==', selectedCollection.name),
            where('uid', '==', selectedCollection.uid), // Filter by the selected collection's UID
            orderBy('order')
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const newEntries = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            console.log("Received entries:", newEntries);
            setEntries(newEntries);
        }, (error) => {
            console.error("Error fetching real-time entries: ", error);
        });

        // Cleanup function to unsubscribe from the current subscription when the component unmounts or the collection name changes
        return () => {
            console.log("Unsubscribing from collection:", selectedCollection);
            unsubscribe();
        };
    }, [selectedCollection, user]);

    const requestDeleteCollection = (collectionId, collectionName) => {
        setCollectionToDelete({ id: collectionId, name: collectionName });
        setShowDeleteConfirmModal(true);
    };

    const formatLongWords = (text) => {
        const words = text.split(' ');
        return words.map(word => {
            if (word.length > 26) {
                // Insert a space every 26 characters in the word
                return word.replace(/(.{26})/g, '$1 ');
            }
            return word;
        }).join(' ');
    };

    const handleCollectionClick = useCallback((collection) => {
        setSelectedCollection(collection);
    }, []);

    const handleEditCollectionClick = (collectionId, collectionName) => {
        console.log("Editing:", collectionId, collectionName);
        setEditingCollectionId(collectionId); // Save the ID of the collection being edited
        setEditingCollectionName(collectionName); // Save the current name to populate in the edit modal
        setShowEditModal(true); // Show the edit modal
    };

    const handleEditCollectionSave = async () => {
        if (editingCollectionName.trim() !== '' && selectedCollection) {
            console.log("should be in here"); // Log the value

            try {
                console.log("Attempting to save edit for ID:", editingCollectionId);

                // Update the collection name in Firestore using the stored ID
                const collectionRef = doc(db, 'collections', editingCollectionId);
                await updateDoc(collectionRef, {
                    name: editingCollectionName.trim(),
                    isPublic: isCollectionPublic, // Update isPublic status
                });

                // Update the local state to reflect the change
                setCollections(collections.map(collection =>
                    collection.id === editingCollectionId ? { ...collection, name: editingCollectionName.trim(), isPublic: isCollectionPublic } : collection
                ));

                // Fetch all entries associated with the old collection name
                const messagesQuery = query(collection(db, 'messages'), where('collectionName', '==', selectedCollection.name));
                const querySnapshot = await getDocs(messagesQuery);

                // Create a batch to update all entries
                const batch = writeBatch(db);

                querySnapshot.forEach((docSnapshot) => {
                    // For each document, update the collectionName
                    const messageDocRef = doc(db, 'messages', docSnapshot.id);
                    batch.update(messageDocRef, { collectionName: editingCollectionName.trim() });
                });

                // Commit the batch
                await batch.commit();

                // Update entries in local state
                setEntries(entries.map(entry => ({
                    ...entry,
                    collectionName: entry.collectionName === selectedCollection.name ? editingCollectionName.trim() : entry.collectionName,
                })));

                setShowEditModal(false); // Hide the modal
            } catch (error) {
                console.error('Error updating collection and messages:', error);
                alert("There was an error updating the collection name and associated entries.");
            }
        } else {
            alert("Please enter a collection name.");
        }
    };

    function formatResponseText(text) {
        // Replace newline characters with <br /> tags
        return text.split('\n').map((item, key) => {
            return (
                <span key={key}>
                    {item}
                    <br />
                </span>
            );
        });
    }

    const toggleEntrySelection = useCallback((entryId) => {
        setSelectedEntries(prev => ({
            ...prev,
            [entryId]: !prev[entryId]
        }));
    }, []);

    const saveAIResponse = async () => {
        console.log('Attempting to save AI response', formattedResponse);
        if (!formattedResponse || !user) {
            console.error('No response to save or user not logged in');
            return;
        }

        // Recursive function to convert React elements and their children to a string
        const elementToString = (element) => {
            // If it's a string, number, or similar, return it as is
            if (typeof element === 'string' || typeof element === 'number' || typeof element === 'boolean') {
                return String(element);
            }

            // If it's an array, process each element
            if (Array.isArray(element)) {
                return element.map(child => elementToString(child)).join('');
            }

            // If it's a React element with children, process the children
            if (element && element.props && element.props.children) {
                return elementToString(element.props.children);
            }

            // For any other type of object, return an empty string
            return '';
        };

        // Convert the formattedResponse (array of React elements) to a plain string
        const responseString = formattedResponse.map(element => elementToString(element)).join('\n');

        try {
            const newEntry = {
                content: responseString,  // Use the converted string for the content field
                category: 'Hallucination', // predefined category
                hallucinationResponse: "yes",
                userPrompt: userPrompt, // include the userPrompt here
                collectionName: "", // no specific collection
                order: null, // no specific order
                uid: user.uid, // user's UID from the authentication state
                timestamp: new Date(), // current timestamp
            };

            // Add the new entry to the 'messages' collection
            await addDoc(collection(db, 'messages'), newEntry);

            console.log('Hallucination response saved successfully!');
            // Optionally, reset analysisResponse or provide user feedback
            setAnalysisResponse(''); // reset analysis response if needed
            setUserPrompt('');
            alert('Your response has been saved successfully!');

        } catch (error) {
            console.error('Error saving AI response:', error);
            // Optionally, provide user feedback about the error
        }
    };

    const saveComplexResponse = async () => {
        console.log('Attempting to save AI response', formattedResponse);
        if (!formattedResponse || !user) {
            console.error('No response to save or user not logged in');
            return;
        }

        // Recursive function to convert React elements and their children to a string
        const elementToString = (element) => {
            // If it's a string, number, or similar, return it as is
            if (typeof element === 'string' || typeof element === 'number' || typeof element === 'boolean') {
                return String(element);
            }

            // If it's an array, process each element
            if (Array.isArray(element)) {
                return element.map(child => elementToString(child)).join('');
            }

            // If it's a React element with children, process the children
            if (element && element.props && element.props.children) {
                return elementToString(element.props.children);
            }

            // For any other type of object, return an empty string
            return '';
        };

        // Convert the formattedResponse (array of React elements) to a plain string
        const responseString = formattedResponse.map(element => elementToString(element)).join('\n');

        try {
            const newEntry = {
                content: responseString,  // Use the converted string for the content field
                category: 'Complex Query', // predefined category
                complexQuery: "yes",
                userPrompt: userPrompt, // include the userPrompt here
                collectionName: "", // no specific collection
                order: null, // no specific order
                uid: user.uid, // user's UID from the authentication state
                timestamp: new Date(), // current timestamp
            };

            // Add the new entry to the 'messages' collection
            await addDoc(collection(db, 'messages'), newEntry);

            console.log('Complex query response saved successfully!');
            // Optionally, reset analysisResponse or provide user feedback
            setAnalysisResponse(''); // reset analysis response if needed
            setUserPrompt('');
            alert('Your response has been saved successfully!');

        } catch (error) {
            console.error('Error saving AI response:', error);
            // Optionally, provide user feedback about the error
        }
    };

    const analyzeEntries = async () => {
        setIsAnalyzing(true); // Start analyzing
        setAnalysisResponse('Thinking...'); // Immediately show "Thinking..." when analysis starts
        const functions = getFunctions();
        const analyzeJournalEntries = httpsCallable(functions, 'analyzeJournalEntries');
        const selectedContent = entries
            .filter(entry => selectedEntries[entry.id])
            .map(entry => entry.content)
            .join('\n');

        try {
            const response = await analyzeJournalEntries({
                collectionName: selectedCollection.name,
                prompt: userPrompt,
                content: selectedContent
            });
            const responseFormattedText = formatResponseText(response.data.analysis);
            setAnalysisResponse(responseFormattedText);
            setFormattedResponse(responseFormattedText);
        } catch (error) {
            console.error('Error analyzing entries:', error);
            setAnalysisResponse('Error analyzing entries. Please try again.');
        }
        setIsAnalyzing(false); // Finish analyzing
    };

    const complexQuery = async () => {
        setIsAnalyzing(true); // Start analyzing
        setAnalysisResponse('Thinking...'); // Immediately show "Thinking..." when analysis starts
        const functions = getFunctions();
        const complexQuery = httpsCallable(functions, 'complexQuery');
        const selectedContent = entries
            .filter(entry => selectedEntries[entry.id])
            .map(entry => entry.content)
            .join('\n');

        try {
            const response = await complexQuery({
                collectionName: selectedCollection.name,
                prompt: userPrompt,
                content: selectedContent
            });
            const responseFormattedText = formatResponseText(response.data.analysis);
            setAnalysisResponse(responseFormattedText);
            setFormattedResponse(responseFormattedText);
        } catch (error) {
            console.error('Error analyzing entries:', error);
            setAnalysisResponse('Error analyzing entries. Please try again.');
        }
        setIsAnalyzing(false); // Finish analyzing
    };

    const CollectionEntries = ({ entries }) => {

        const onDragEnd = async (result) => {
            if (!result.destination) {
                return;
            }

            const reorderedEntries = Array.from(entries);
            const [removed] = reorderedEntries.splice(result.source.index, 1);
            reorderedEntries.splice(result.destination.index, 0, removed);

            // Update the order in the local state
            setEntries(reorderedEntries);

            // Update the order in Firestore
            for (let i = 0; i < reorderedEntries.length; i++) {
                const entry = reorderedEntries[i];
                const entryRef = doc(db, 'messages', entry.id);
                await updateDoc(entryRef, { order: i });
            }
        };

        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {entries.map((entry, index) => (
                                <Draggable key={entry.id} draggableId={entry.id} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                ...provided.draggableProps.style,
                                                borderBottom: '1px solid #333',
                                                marginBottom: '16px',
                                                cursor: 'pointer',
                                                display: 'flex', // Use flexbox
                                                alignItems: 'center', // Vertically center align items
                                                padding: '8px 0', // Vertical padding for breathing room
                                            }}
                                        >
                                            <span
                                                onClick={() => toggleEntrySelection(entry.id)}
                                                className="material-symbols-outlined"
                                                style={{
                                                    cursor: 'pointer',
                                                    color: selectedEntries[entry.id] ? 'rgb(121, 84, 180)' : 'rgba(80, 80, 80, 1)',
                                                    fontSize: '24px',
                                                    marginRight: '16px', // Space between the radio button and text
                                                    marginLeft: '16px',
                                                    marginBottom: '16px',
                                                }}
                                            >
                                                {selectedEntries[entry.id] ? 'radio_button_checked' : 'radio_button_unchecked'}
                                            </span>

                                            <div style={{
                                                flexGrow: 1, // Allow this div to take up the remaining space
                                                display: 'flex', // Use flexbox for internal alignment
                                                flexDirection: 'column', // Stack content vertically
                                                justifyContent: 'center', // Center content vertically
                                            }}>
                                                <p style={{ margin: '0 16px 16px 0', padding: '0', maxWidth: 'calc(100% - 64px)' }}>
                                                    {formatLongWords(entry.content)} {/* Use the formatLongWords function here */}
                                                </p>

                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };

    // Recursive function to convert React elements and their children to a string
    const elementToString = (element) => {
        // If it's a string, number, or similar, return it as is
        if (typeof element === 'string' || typeof element === 'number' || typeof element === 'boolean') {
            return String(element);
        }

        // If it's an array, process each element
        if (Array.isArray(element)) {
            return element.map(child => elementToString(child)).join('');
        }

        // If it's a React element with children, process the children
        if (element && element.props && element.props.children) {
            return elementToString(element.props.children);
        }

        // For any other type of object, return an empty string
        return '';
    };

    const renderSaveHallucinationResponseButton = () => {
        const responseString = Array.isArray(formattedResponse)
            ? formattedResponse.map(element => elementToString(element)).join('\n')
            : formattedResponse;

        if (!isAnalyzing && responseString && responseString.trim() !== '') {
            return (
                <button
                    onClick={saveAIResponse}
                    style={{
                        display: 'block',
                        margin: '8px 0px',
                        backgroundColor: 'rgb(121, 84, 180)',
                        color: '#fff',
                        padding: '10px 15px',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}>
                    Save Hallucinated Response
                </button>
            );
        }
        return null;
    };

    const renderSaveComplexResponseButton = () => {
        const responseString = Array.isArray(formattedResponse)
            ? formattedResponse.map(element => elementToString(element)).join('\n')
            : formattedResponse;

        if (!isAnalyzing && responseString && responseString.trim() !== '') {
            return (
                <button
                    onClick={saveComplexResponse}
                    style={{
                        display: 'block',
                        margin: '8px 0px',
                        backgroundColor: 'rgb(121, 84, 180)',
                        color: '#fff',
                        padding: '10px 15px',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}>
                    Save Complex Response
                </button>
            );
        }
        return null;
    };

    const onTogglePrivacy = useCallback((collectionId, isPublic) => {
        const collectionRef = doc(db, 'collections', collectionId);
        updateDoc(collectionRef, { isPublic: !isPublic })
            .then(() => {
                setCollections(prev => prev.map(coll => {
                    if (coll.id === collectionId) {
                        return { ...coll, isPublic: !isPublic };
                    }
                    return coll;
                }));
            })
            .catch(error => {
                console.error("Error updating privacy status:", error);
            });
    }, [db]);

    const handleCreateCollection = async () => {
        let username = 'Unknown'; // Default username if not found
        setIsLoading(true);
      
        try {
          // First fetch the username from the 'users' collection
          const usersRef = collection(db, 'users');
          const userQuerySnapshot = await getDocs(query(usersRef, where('UID', '==', user.uid)));
      
          if (!userQuerySnapshot.empty) {
            const userDoc = userQuerySnapshot.docs[0]; // Get the first document matching the UID
            const userData = userDoc.data();
            username = userData.username; // Access the username
          } else {
            // Log if no user data is found
            console.error('No user found with the UID:', user.uid);
          }
      
          // Now create the new collection document with the username included
          const collectionRef = await addDoc(collection(db, 'collections'), {
            name: newCollectionName,
            uid: user.uid,
            creatorUsername: username, // Include the fetched username
            collaborators: [],
            originalCreatorUID: user.uid, // This identifies the user as the original creator
            isPublic: isCollectionPublic, // Managed by a state hook
          });
      
          // Update local state with the new collection
          setCollections(prevCollections => [
            ...prevCollections,
            {
              id: collectionRef.id,
              name: newCollectionName,
              isPublic: isCollectionPublic,
              creatorUsername: username,
              uid: user.uid
            }
          ]);
      
          handleCloseModal();
          setIsLoading(false);
        } catch (error) {
          console.error('Error creating collection:', error);
          setIsLoading(false);
        }
      };
    

    useEffect(() => {
        if (!user) return; // Exit if no user is logged in

        const createdByUserQuery = query(collection(db, 'collections'), where('uid', '==', user.uid));
        const collaboratorQuery = query(collection(db, 'collections'), where('collaborators', 'array-contains', user.uid));

        const fetchCollections = async () => {
            try {
                const [createdByUserSnapshot, collaboratorSnapshot] = await Promise.all([
                    getDocs(createdByUserQuery),
                    getDocs(collaboratorQuery)
                ]);

                // Assume that the username of the creator is stored in the collection under a field named 'username'
                const userOwnedCollections = createdByUserSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    isOwner: true,
                    creatorUsername: doc.data().creatorUsername, // Add this line
                }));

                // Include creatorUsername for collaborator collections if available
                const collaboratorCollections = collaboratorSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    isOwner: false,
                    creatorUsername: doc.data().creatorUsername, // Add this line
                }));

                // Combine both arrays while ensuring no duplicates
                const combinedCollections = [...userOwnedCollections, ...collaboratorCollections]
                    .reduce((acc, collection) => {
                        if (!acc.find(c => c.id === collection.id)) {
                            acc.push(collection);
                        }
                        return acc;
                    }, []);

                setCollections(combinedCollections);
            } catch (error) {
                console.error('Error fetching collections:', error);
            }
        };

        fetchCollections();
    }, [user, db]);

    const deleteCollection = async (collectionId, collectionName) => {
        try {
            // Fetch all references of the collection by name and id
            const collectionRef = doc(db, "collections", collectionId);
            const collectionSnapshot = await getDoc(collectionRef);

            if (!collectionSnapshot.exists) {
                console.error("Notebook not found");
                alert("Notebook not found");
                return;
            }

            const collectionData = collectionSnapshot.data();
            const batch = writeBatch(db);

            if (collectionData.uid === user.uid) {
                // User is the original creator of this collection
                batch.delete(collectionRef);
                alert("Notebook deleted successfully");
                setSelectedCollection(null); // Reset to show all collections
            } else if (collectionData.collaborators && collectionData.collaborators.includes(user.uid)) {
                // User is a collaborator, not the creator
                const newCollaborators = collectionData.collaborators.filter(uid => uid !== user.uid);
                batch.update(collectionRef, { collaborators: newCollaborators });
                alert("You've been removed as a subscriber to this notebook");
            } else {
                console.error("You are not authorized to delete this notebook");
                alert("You are not authorized to delete this notebook");
                return;
            }

            // Commit changes to Firestore
            await batch.commit();

            // Update local state to reflect this change
            setCollections(collections.filter(coll => !(coll.id === collectionId && coll.name === collectionName)));
            setSelectedCollectionName(null);  // Resetting to show all collections

        } catch (error) {
            console.error("Error deleting/removing collection:", error);
            alert("There was an error processing your request.");
        } finally {
            setShowDeleteConfirmModal(false);
        }
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <i
            className="material-icons"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ cursor: 'pointer' }} // Add custom styles here
        >
            more_horiz
        </i>
    ));

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/');
            console.log('Signed out successfully');
            console.log("User Object:", JSON.stringify(user, null, 2));
        } catch (error) {
            console.error('Sign-out error:', error);
        }
    };

    return (
        <div>
            <Navigation user={user} handleLogout={handleLogout} />

            {selectedCollection ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px', paddingLeft: '20px', paddingRight: '28px' }}>
                        <a href="#" style={{ textDecoration: 'underline', color: 'rgb(121, 84, 180)' }} onClick={() => setSelectedCollection(null)}>
                            View all
                        </a>

                        <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => {
                                    const selectedCollectionObj = collections.find(c => c.id === selectedCollection.id);
                                    if (selectedCollectionObj) {
                                        handleEditCollectionClick(selectedCollectionObj.id, selectedCollectionObj.name);
                                    } else {
                                        console.error("Collection not found");
                                    }
                                }}>Edit Collection Name</Dropdown.Item>
                                <Dropdown.Item onClick={() => requestDeleteCollection(selectedCollection.id, selectedCollection.name)}>Delete Collection</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div style={{
                        backgroundColor: 'rgb(38,38,38)',
                        padding: '2px',
                        maxWidth: '992px',
                        borderRadius: '6px',
                        marginLeft: '16px',
                        marginTop: '16px',
                        marginBottom: '16px',
                        marginRight: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            width: '100%',
                        }}>
                            <button onClick={handleComplexQueryClick} style={showComplexQueryUI ? activeStyle : inactiveStyle}>
                                Complex Query
                            </button>
                            <button onClick={handleHallucinateClick} style={showHallucinateButton ? activeStyle : inactiveStyle}>
                                Hallucinate
                            </button>
                        </div>

                        {showComplexQueryUI && (
                            <div style={{ width: '100%', padding: '16px' }}>
                                {/* Complex Query UI Components */}
                                <input
                                    type="text"
                                    value={userPrompt}
                                    onChange={(e) => setUserPrompt(e.target.value)}
                                    placeholder="Enter your query..."
                                    style={{ padding: '12px', border: '0', color: 'rgb(250,250,250)', borderRadius: '8px', backgroundColor: 'rgb(50,50,50)', width: '100%' }}
                                />
                                <button
                                    style={{ display: 'block', margin: '16px 0px', backgroundColor: 'rgb(121, 84, 180)', color: '#fff', padding: '10px 15px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                                    onClick={complexQuery}>
                                    Send
                                </button>
                            </div>
                        )}

                        {showHallucinateButton && (
                            <div style={{ width: '100%', padding: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <p style={{ textAlign: 'center' }}>Select some entries to cut-up and create a new text.</p>
                                <button
                                    style={{ margin: '16px 0px', backgroundColor: 'rgb(121, 84, 180)', color: '#fff', padding: '10px 15px', border: 'none', borderRadius: '5px', cursor: 'pointer', width: '120px', }}
                                    onClick={analyzeEntries}>
                                    Hallucinate
                                </button>
                            </div>
                        )}
                    </div>
                    {/* Display OpenAI response */}
                    {isAnalyzing || analysisResponse ? (
                        <div style={{ margin: '16px', maxWidth: '992px', backgroundColor: 'rgb(38,38,38)', padding: '24px', borderRadius: '6px' }}>
                            <p>{analysisResponse}</p>
                            {!isAnalyzing && showHallucinateButton && analysisResponse && renderSaveHallucinationResponseButton()} {/* Render the save button only when not analyzing */}
                            {!isAnalyzing && showComplexQueryUI && analysisResponse && renderSaveComplexResponseButton()} {/* Render the save button only when not analyzing */}
                        </div>
                    ) : null}
                    {/* Display entries for the selected collection */}
                    <CollectionEntries collectionName={selectedCollection.name} db={db} entries={entries} setEntries={setEntries} selectedEntries={selectedEntries} />
                </>

            ) : (
                <>
                    {/* Button to create new collection */}
                    <div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            width: '100%',
                        }}>
                        </div>
                    </div>
                    <div className="cosine-sim-container" style={{ marginLeft: '8px', marginRight: '8px', marginTop: '8px' }}>
                        <div
                            className="cosine-sim-card card m-2"
                            style={{
                                backgroundColor: 'transparent',
                                border: '2px dashed rgb(100, 100, 100)',
                                color: 'rgb(245, 245, 245)',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '240px'
                            }}
                            onClick={handleShowModal}
                        >
                            <div className="card-body" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <h5 className="card-title" style={{ margin: 0 }}>+ Notebook</h5>
                            </div>
                        </div>
                        {collections.map(collection => (
                            <CollectionCard
                                key={collection.id}
                                collection={collection}
                                onClick={handleCollectionClick}
                                onTogglePrivacy={onTogglePrivacy}
                            />
                        ))}
                    </div>
                </>
            )}
            <Modal show={showDeleteConfirmModal} onHide={() => setShowDeleteConfirmModal(false)}>
                <Modal.Header>
                    <p style={{ color: 'rgb(200, 200, 200)' }}>
                        <b>Confirm Deletion</b>
                    </p>
                    <div className="modalClose" onClick={() => setShowDeleteConfirmModal(false)}> {/* Corrected function call */}
                        <i className="material-icons" style={{ color: 'rgb(100, 100, 100)', cursor: 'pointer' }}>
                            close
                        </i>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: 'rgb(50,50,50)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', }}>
                    {`Are you sure you want to delete the collection "${collectionToDelete?.name}"?`}<br></br><br></br>{`This action cannot be undone.`}
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
                        <Button style={{ backgroundColor: 'rgb(121, 84, 180)' }} onMouseEnter={(e) => {
                            e.target.style.backgroundColor = 'rgb(90, 59, 138)'; // Change background color on hover
                            e.target.style.borderColor = 'rgb(90, 59, 138)'; // Change background color on hover
                        }}
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor = 'rgb(121, 84, 180)'; // Restore background color on mouse leave
                            }} onClick={() => deleteCollection(collectionToDelete.id, collectionToDelete.name)}>
                            Delete Collection
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header>
                    <p style={{ color: 'rgb(200, 200, 200)' }}>
                        <b>Edit Collection Name</b>
                    </p>
                    <div className="modalClose" onClick={() => setShowCollectionModal(false)}>
                        <i className="material-icons" onClick={() => setShowEditModal(false)} style={{ color: 'rgb(100, 100, 100)', cursor: 'pointer' }}>
                            close
                        </i>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ borderColor: 'rgb(121, 84, 180)', backgroundColor: 'rgb(53, 53, 53)', paddingBottom: '24px', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}>
                    <Form.Group controlId="editCollectionName">
                        <Form.Control
                            type="text"
                            placeholder={editingCollectionName || "Enter new collection name"}
                            value={editingCollectionName}
                            onChange={(e) => setEditingCollectionName(e.target.value)}
                        />
                    </Form.Group>
                    <Button style={{ backgroundColor: 'rgb(121, 84, 180)', float: 'right', marginTop: '18px' }} onMouseEnter={(e) => {
                        e.target.style.backgroundColor = 'rgb(90, 59, 138)'; // Change background color on hover
                        e.target.style.borderColor = 'rgb(90, 59, 138)'; // Change background color on hover
                    }}
                        onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'rgb(121, 84, 180)'; // Restore background color on mouse leave
                            e.target.style.borderColor = 'rgb(121, 84, 180)'; // Change background color on hover
                        }} onClick={handleEditCollectionSave}>
                        Save Changes
                    </Button>
                </Modal.Body>
            </Modal>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: '18px', color: 'rgb(200, 200, 200)', paddingTop: '16px', paddingBottom: '16px' }}>Create New Notebook</Modal.Title>
                    <button className="btn-close" style={{ color: 'rgb(80,80,80) !important' }} onClick={handleCloseModal}></button>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: 'rgb(53, 53, 53)' }}>
                    <Form.Group controlId="formCollectionName">
                        <Form.Control
                            type="text"
                            placeholder="Enter notebook name"
                            value={newCollectionName}
                            onChange={(e) => setNewCollectionName(e.target.value)}
                            className="custom-input"
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: 'rgb(53, 53, 53)', borderTop: 'none' }}>
                    <Button style={{ backgroundColor: 'rgb(121, 84, 180)' }} onClick={handleCreateCollection}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default CollectionView;
