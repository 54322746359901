import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { auth, db, functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import Navigation from './Navigation';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { signOut } from 'firebase/auth';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { collection, getDocs, query, where, addDoc, writeBatch, deleteDoc } from 'firebase/firestore';
import stringSimilarity from 'string-similarity';

import './ContentMap.css';

const CACHE_KEY_PREFIX = 'cached_';

const ContentMap = () => {
  const svgRef = useRef(null);
  const nodesRef = useRef([]);
  const linksRef = useRef([]);
  const zoomTransformRef = useRef(d3.zoomIdentity);
  const [topicCounts, setTopicCounts] = useState({});
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedClusterTitle, setSelectedClusterTitle] = useState('');
  const [syncing, setSyncing] = useState(false);
  const [publicCollections, setPublicCollections] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [showRecommendations, setShowRecommendations] = useState(false);

  const getCacheKey = (key, uid) => `${CACHE_KEY_PREFIX}${key}_${uid}`;

  useEffect(() => {
    if (user) {
      const initialLoad = localStorage.getItem(getCacheKey('initialLoad', user.uid));
      if (!initialLoad) {
        handleUserChange(user.uid, false);
        localStorage.setItem(getCacheKey('initialLoad', user.uid), 'true');
      } else {
        handleUserChange(user.uid, false);
      }
    } else {
      clearSvg();
      setLoading(true);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchPublicCollections();
    }
  }, [user]);

  useEffect(() => {
    if (!loading && nodesRef.current.length > 0 && linksRef.current.length > 0) {
      drawLinkForceGraph();
    }
  }, [loading]);

  const fetchPublicCollections = async () => {
    const collections = [];
    try {
      const collectionsQuerySnapshot = await getDocs(collection(db, 'collections'));
      collectionsQuerySnapshot.forEach((doc) => {
        const collectionData = doc.data();
        if (collectionData.isPublic) {
          collections.push({
            uid: collectionData.uid,
            name: collectionData.name,
            username: collectionData.creatorUsername,
          });
        }
      });
      console.log('Fetched Public Collections:', collections);
    } catch (error) {
      console.error('Error fetching public collections:', error);
    }
    setPublicCollections(collections);
  };

  const handleUserChange = async (uid, clearCache = true) => {
    if (clearCache) {
      clearCacheData(uid);
    }
    const cachedNodes = localStorage.getItem(getCacheKey('nodes', uid));
    const cachedLinks = localStorage.getItem(getCacheKey('links', uid));
    const cachedTopicCounts = localStorage.getItem(getCacheKey('topicCounts', uid));

    if (cachedNodes && cachedLinks && cachedTopicCounts) {
      nodesRef.current = JSON.parse(cachedNodes);
      linksRef.current = JSON.parse(cachedLinks);
      setTopicCounts(JSON.parse(cachedTopicCounts));
      setLoading(false);
    } else {
      clearSvg();
      setLoading(true);
      await fetchData(uid);
    }
  };

  const clearCacheData = (uid) => {
    localStorage.removeItem(getCacheKey('nodes', uid));
    localStorage.removeItem(getCacheKey('links', uid));
    localStorage.removeItem(getCacheKey('topicCounts', uid));
    localStorage.removeItem(getCacheKey('initialLoad', uid));
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith(getCacheKey('clusterTitle_', uid))) {
        localStorage.removeItem(key);
      }
    });
  };

  const fetchData = async (uid) => {
    const fetchUserVectors = httpsCallable(functions, 'fetchUserVectors');

    try {
      const response = await fetchUserVectors({ uid });
      if (response.data && response.data.vectors) {
        console.log('Fetched Vectors:', response.data.vectors);
        await processVectors(response.data.vectors, uid);
      } else {
        console.error('No vectors found in response:', response);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching user vectors:', error);
      setLoading(false);
    }
  };

  const processVectors = async (vectors, uid) => {
    const fetchSimilar = httpsCallable(functions, 'fetchSimilarVectors');
    const topicCounter = {};
    const topicMap = {};
    const vectorNodesMap = new Map();

    const newNodes = [];
    const newLinks = [];

    for (const vector of vectors) {
      if (!vector.metadata || !vector.metadata.category) {
        console.error('Skipping vector due to missing metadata or category:', vector);
        continue;
      }

      const newNode = {
        id: vector.id,
        name: vector.metadata.content,
        category: vector.metadata.category,
        x: Math.random() * 1920,
        y: Math.random() * 1080
      };

      newNodes.push(newNode);
      vectorNodesMap.set(vector.id, vector);

      try {
        const response = await fetchSimilar({ id: vector.id });
        if (response.data && response.data.similarVectors) {
          response.data.similarVectors.forEach(similarVector => {
            if (!similarVector.metadata || !similarVector.metadata.category) {
              console.error('Skipping similar vector due to missing metadata or category:', similarVector);
              return;
            }

            if (vectorNodesMap.has(similarVector.id)) {
              newLinks.push({
                source: vector.id,
                target: similarVector.id,
                strength: similarVector.score
              });

              const category = similarVector.metadata.category;
              topicCounter[category] = (topicCounter[category] || 0) + 1;

              if (!topicMap[category]) {
                topicMap[category] = { name: category, children: [] };
              }

              topicMap[category].children.push({
                id: similarVector.id,
                name: similarVector.metadata.content || 'No Content',
                kValue: similarVector.score,
              });
            }
          });
        } else {
          console.error('No similar vectors found in response for vector ID:', vector.id, response);
        }
      } catch (error) {
        console.error('Error fetching similar vectors:', error);
      }
    }

    const strongestTopic = Object.keys(topicCounter).reduce((a, b) => (topicCounter[a] > topicCounter[b] ? a : b));

    const root = { name: strongestTopic, children: Object.values(topicMap) };

    setTopicCounts(topicCounter);
    nodesRef.current = newNodes;
    linksRef.current = newLinks;

    localStorage.setItem(getCacheKey('nodes', uid), JSON.stringify(newNodes));
    localStorage.setItem(getCacheKey('links', uid), JSON.stringify(newLinks));
    localStorage.setItem(getCacheKey('topicCounts', uid), JSON.stringify(topicCounter));
    setLoading(false);
  };

  const identifyLargeClusters = (sizeThreshold = 5) => {
    const clusters = {};
    nodesRef.current.forEach(node => {
      const connections = linksRef.current.filter(link => link.source.id === node.id || link.target.id === node.id);
      connections.forEach(connection => {
        const clusterKey = node.cluster || node.id;
        if (!clusters[clusterKey]) {
          clusters[clusterKey] = [];
        }
        clusters[clusterKey].push(node);
        clusters[clusterKey].push(connection.source);
        clusters[clusterKey].push(connection.target);
      });
    });

    Object.keys(clusters).forEach(clusterKey => {
      clusters[clusterKey] = [...new Set(clusters[clusterKey])];
    });

    const largeClusters = Object.values(clusters).filter(cluster => cluster.length > sizeThreshold);
    console.log('Identified Clusters:', clusters);
    console.log('Large Clusters:', largeClusters);
    return largeClusters;
  };

  const saveSearchQueryToFirestore = async (title, content) => {
    try {
      const newTimestamp = new Date();
      const newDocRef = await addDoc(collection(db, 'searchQueries'), {
        uid: user.uid,
        title: title,
        content: content,
        timestamp: newTimestamp
      });
  
      console.log('New search query saved to Firestore with ID:', newDocRef.id, { title, content });
    } catch (error) {
      console.error('Error saving search query to Firestore:', error);
    }
  };

  const extractUniqueKeywords = (content) => {
    const allWords = content.split(/\W+/).map(word => word.toLowerCase());
    const wordCounts = {};
    allWords.forEach(word => {
      if (word.length > 2) {
        wordCounts[word] = (wordCounts[word] || 0) + 1;
      }
    });
    const sortedWords = Object.entries(wordCounts).sort((a, b) => b[1] - a[1]);
    const uniqueKeywords = sortedWords.slice(0, 5).map(entry => entry[0]);
    return uniqueKeywords.join(' ');
  };

  const generateClusterTitle = async (content, clusterIndex) => {
    const cachedTitleKey = getCacheKey(`clusterTitle_${clusterIndex}`, user.uid);
    const cachedTitle = localStorage.getItem(cachedTitleKey);
  
    if (cachedTitle && !syncing) {
      return cachedTitle;
    }
  
    const uniqueKeywords = extractUniqueKeywords(content);
    const generateTitle = httpsCallable(functions, 'generateClusterTitle');
    try {
      const response = await generateTitle({ content: `${uniqueKeywords} ${content}` });
      console.log('Generated Title:', response.data.title);
      localStorage.setItem(cachedTitleKey, response.data.title);
      await saveSearchQueryToFirestore(response.data.title, content);
      return response.data.title;
    } catch (error) {
      console.error('Error generating cluster title:', error);
      return 'Untitled Cluster';
    }
  };

  const drawLinkForceGraph = async () => {
    const width = 1920;
    const height = 1080;
  
    const svg = d3.select(svgRef.current)
      .attr("viewBox", [0, 0, width, height])
      .style("font", "10px sans-serif");
  
    svg.selectAll("*").remove();
  
    const g = svg.append("g");
  
    const nodeConnections = {};
  
    const updateNodeConnections = () => {
      Object.keys(nodeConnections).forEach(key => delete nodeConnections[key]);
      linksRef.current.forEach(link => {
        nodeConnections[link.source.id] = (nodeConnections[link.source.id] || 0) + 1;
        nodeConnections[link.target.id] = (nodeConnections[link.target.id] || 0) + 1;
      });
    };
  
    updateNodeConnections();
  
    const getRadiusScale = () => {
      const minConnections = d3.min(Object.values(nodeConnections));
      const maxConnections = d3.max(Object.values(nodeConnections));
      return d3.scalePow().exponent(2)
        .domain([minConnections, maxConnections])
        .range([5, 15]);
    };
  
    const linkForce = d3.forceLink(linksRef.current).id(d => d.id).strength(d => d.strength);
    const chargeForce = d3.forceManyBody().strength(-200);
    const collisionForce = d3.forceCollide().radius(d => getRadiusScale()(nodeConnections[d.id] || 1) + 5);
  
    const simulation = d3.forceSimulation(nodesRef.current)
      .force("link", linkForce)
      .force("charge", chargeForce)
      .force("collision", collisionForce)
      .force("center", d3.forceCenter(width / 2, height / 2));
  
    const link = g.append("g")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .selectAll("line")
      .data(linksRef.current)
      .join("line")
      .attr("stroke-width", d => Math.sqrt(d.strength * 10));
  
    let selectedNodeId = null;
  
    const node = g.append("g")
      .attr("stroke-width", 1.5)
      .selectAll("g")
      .data(nodesRef.current)
      .join("g")
      .call(drag(simulation))
      .on("click", async (event, d) => {
        setSelectedNode(d);
        setSelectedClusterTitle('');
  
        selectedNodeId = d.id;
  
        const largeClusters = identifyLargeClusters();
        for (let i = 0; i < largeClusters.length; i++) {
          const cluster = largeClusters[i];
          if (cluster.includes(d)) {
            const content = cluster.map(node => node.name).join('\n');
            const title = await generateClusterTitle(content, i);
            setSelectedClusterTitle(title);
            break;
          }
        }
  
        if (getRadiusScale()(nodeConnections[d.id] || 1) > 8) {
          await recommendCollections(d.id);
        } else {
          setRecommendations([]);
          setShowRecommendations(false);
        }
      });
  
    node.append("circle")
      .attr("fill", d => d3.schemeCategory10[d.category.charCodeAt(0) % 10]);
  
    node.append("text")
      .attr("x", 12)
      .attr("y", "0.31em")
      .style("fill", "white")
      .text(d => `${d.name.substring(0, 36)}...`);
  
    node.append("title")
      .text(d => d.name);
  
    const largeClusters = identifyLargeClusters();
    console.log('Large Clusters:', largeClusters);
      
    let clusterTitles = [];
    for (let i = 0; i < largeClusters.length; i++) {
      const cluster = largeClusters[i];
      const content = cluster.map(node => node.name).join('\n');
      const title = await generateClusterTitle(content, i);
      const power = calculateClusterPower(cluster);
      
      clusterTitles.push({ 
        title, 
        cluster, 
        power 
      });
    }
    
    clusterTitles = consolidateSimilarTitles(clusterTitles);
    console.log('Consolidated Cluster Titles:', clusterTitles);

    simulation.on("tick", () => {
      updateNodeConnections();
      const radiusScale = getRadiusScale();
      
      link
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);
    
      node.attr("transform", d => `translate(${d.x},${d.y})`)
        .select("circle")
          .attr("r", d => radiusScale(nodeConnections[d.id] || 1))
          .attr("class", d => {
            const classes = [];
            if (radiusScale(nodeConnections[d.id] || 1) > 8) classes.push("large-node");
            if (d.id === selectedNodeId) classes.push("selected-node");
            return classes.join(" ");
          })
          .attr("stroke", d => (d.id === selectedNodeId ? "white" : "none"))
          .attr("stroke-width", d => (d.id === selectedNodeId ? 3 : 0));
    });
  
    const zoom = d3.zoom().on("zoom", (event) => {
      zoomTransformRef.current = event.transform;
      g.attr("transform", event.transform);
    });
  
    svg.call(zoom).call(zoom.transform, zoomTransformRef.current);
  
    function drag(simulation) {
      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      }
  
      function dragged(event, d) {
        d.fx = event.x;
        d.fy = event.y;
      }
  
      function dragended(event, d) {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      }
  
      return d3.drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
    }
  };

  const consolidateSimilarTitles = (clusterTitles) => {
    for (let i = 0; i < clusterTitles.length; i++) {
      for (let j = i + 1; j < clusterTitles.length; j++) {
        if (areTitlesSimilar(clusterTitles[i].title, clusterTitles[j].title)) {
          if (clusterTitles[i].power >= clusterTitles[j].power) {
            clusterTitles[j].title = clusterTitles[i].title;
          } else {
            clusterTitles[i].title = clusterTitles[j].title;
          }
        }
      }
    }
    return clusterTitles;
  };

  const areTitlesSimilar = (title1, title2) => {
    const similarity = stringSimilarity.compareTwoStrings(title1.toLowerCase(), title2.toLowerCase());
    return similarity >= 0.6; // Loosened similarity threshold
  };

  const calculateClusterPower = (cluster) => {
    return cluster.reduce((power, node) => power + (node.importance || 1), 0);
  };

  const handleSync = async () => {
    if (user) {
      setSyncing(true);
      clearCacheData(user.uid);

      await deleteUserSearchQueries(user.uid);

      await handleUserChange(user.uid, true);
      setSyncing(false);
    }
  };
  
  const deleteUserSearchQueries = async (uid) => {
    try {
      const querySnapshot = await getDocs(query(collection(db, 'searchQueries'), where('uid', '==', uid)));
      const batch = writeBatch(db);
  
      querySnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
  
      await batch.commit();
      console.log('Successfully deleted all search queries for user:', uid);
    } catch (error) {
      console.error('Error deleting search queries:', error);
    }
  };
  

  const handleLogout = async () => {
    try {
      await signOut(auth);
      clearSvg();
      navigate('/');
      console.log('Signed out successfully');
    } catch (error) {
      console.error('Sign-out error:', error);
    }
  };

  const clearSvg = () => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();
  };

  const recommendCollections = async (nodeId) => {
    console.log("Recommending collections for node:", nodeId);

    if (!user) {
      console.error('User is not set');
      return;
    }

    const SIMILARITY_THRESHOLD = 0.5;

    try {
      const fetchAllSimilar = httpsCallable(functions, 'fetchAllVectors');
      const response = await fetchAllSimilar({ id: nodeId });

      if (response.data && response.data.similarVectors) {
        const similarVectors = response.data.similarVectors;
        console.log('Similar Vectors:', similarVectors);

        const collectionScores = {};

        for (const vector of similarVectors) {
          console.log('Processing vector:', vector);

          if (vector.metadata && vector.score >= SIMILARITY_THRESHOLD) {
            console.log('Vector metadata:', vector.metadata);

            const { collectionName, uid, username } = vector.metadata;

            if (!collectionName || !uid) {
              console.log('Skipping vector due to missing collectionName or uid:', vector.metadata);
              continue;
            }

            if (uid === user.uid) {
              console.log('Skipping vector because it belongs to the current user:', vector.metadata);
              continue;
            }

            if (username) {
              let matched = false;
              publicCollections.forEach(collection => {
                console.log('Checking public collection:', collection);
                if (collection.uid === uid && collection.name === collectionName) {
                  console.log('Matching public collection found:', collection);
                  matched = true;

                  if (!collectionScores[collectionName]) {
                    collectionScores[collectionName] = {
                      username: username,
                      name: collectionName,
                      score: 0,
                    };
                  }
                  collectionScores[collectionName].score += vector.score;
                }
              });

              if (!matched) {
                console.log('No matching public collection found for:', vector.metadata);
              }
            } else {
              console.error('Username is missing for:', vector.metadata);
            }
          } else {
            console.log('Skipping vector due to missing metadata or low similarity score:', vector);
          }
        }

        console.log('Aggregated collection scores:', collectionScores);

        const sortedRecommendations = Object.values(collectionScores).sort((a, b) => b.score - a.score);

        console.log("Recommendations:", sortedRecommendations);
        setRecommendations(sortedRecommendations);
        setShowRecommendations(sortedRecommendations.length > 0);
      } else {
        console.error('No similar vectors found for node:', nodeId);
        setShowRecommendations(false);
      }
    } catch (error) {
      console.error('Error fetching similar vectors:', error);
      setShowRecommendations(false);
    }
  };

  const dismissRecommendations = () => {
    setShowRecommendations(false);
  };

  return (
    <div>
      <Navigation user={user} handleLogout={handleLogout} />
      <div style={{ position: 'relative' }}>
        <div className="d-flex justify-content-between m-2" style={{ position: 'absolute', zIndex: 10 }}>
          <Button
            id="syncButton"
            onClick={handleSync}
            style={{ display: 'flex', alignItems: 'center', gap: '8px', position: 'relative' }}
          >
            <i className="material-icons icon" style={{ color: 'rgb(200,200,200)', animation: syncing ? 'spin 1s linear infinite' : 'none' }}>sync</i>
            {syncing ? 'Updating...' : 'Update'}
          </Button>
        </div>
        <Card className="m-2" style={{ width: '300px', height: '180px', backgroundColor: 'rgb(40,40,40)', borderColor: 'rgb(60,60,60)', overflowY: 'auto', position: 'absolute', top: '60px', zIndex: 10 }}>
          <Card.Body>
          {selectedClusterTitle && (
              <>
                <Card.Title style={{ fontSize: '18px', color: 'rgb(250,250,250)', marginTop: '10px' }}>Cluster Title</Card.Title>
                <Card.Text style={{ fontSize: '14px', color: 'rgb(250,250,250)', height: 'auto', }}>
                  {selectedClusterTitle}
                </Card.Text>
              </>
            )}
          
            <Card.Title style={{ fontSize: '18px', color: 'rgb(250,250,250)' }}>Node Description</Card.Title>
            <Card.Text style={{ fontSize: '14px', color: 'rgb(250,250,250)', height: 'auto', }}>
              {selectedNode ? selectedNode.name : 'No node selected'}
            </Card.Text>
          
          </Card.Body>
        </Card>
        {showRecommendations && (
          <Card className="m-2" style={{ width: '300px', height: 'auto', maxHeight: '180px', backgroundColor: 'rgb(40,40,40)', borderColor: 'rgb(60,60,60)', overflowY: 'auto', position: 'absolute', top: '250px', zIndex: 10 }}>
            <Card.Body>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                <Card.Title style={{ fontSize: '18px', color: 'rgb(250,250,250)' }}>Recommended Collections</Card.Title>
                <Button variant="link" onClick={dismissRecommendations} style={{ color: 'rgb(200,200,200)', fontSize: '20px', marginTop: '-16px'}}>
                  <i className="material-icons">close</i>
                </Button>
              </div>
              <Card.Text style={{ fontSize: '14px', color: 'rgb(250,250,250)' }}>
                {recommendations.length > 0 ? (
                  recommendations.map((rec, index) => (
                    <div key={index}>
                      <a style={{color:'rgb(148, 103, 189)'}} href={`https://geists.app/geist/${rec.username}`} target="_blank" rel="noopener noreferrer"> <strong>{rec.username}</strong>: {rec.name}</a>
                    </div>
                  ))
                ) : (
                  <span>No recommendations</span>
                )}
              </Card.Text>
            </Card.Body>
          </Card>
        )}
        {loading ? (
          <div></div>
        ) : (
          <div style={{ overflowX: 'auto' }}>
            <svg ref={svgRef} width={1920} height={1080}></svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentMap;
