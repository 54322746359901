import React, { useState, useEffect } from 'react';
import 'material-icons/css/material-icons.css';

function OfflinePage() {
    const [content, setContent] = useState('');
    const [category, setCategory] = useState('');
    const [localEntries, setLocalEntries] = useState([]);

    useEffect(() => {
        // Load the saved entries from local storage
        const savedEntries = JSON.parse(localStorage.getItem('offlineEntries') || '[]');
        setLocalEntries(savedEntries);
    }, []);

    const handleSubmit = () => {
        // Save the new entry to local storage
        const newEntry = { content, category, timestamp: new Date() };
        const updatedEntries = [...localEntries, newEntry];
        localStorage.setItem('offlineEntries', JSON.stringify(updatedEntries));
        
        // Update the state and clear the fields
        setLocalEntries(updatedEntries);
        setContent('');
        setCategory('');

        alert('Entry saved locally. It will be synced once you are back online.');
    };

    return (
        <div className="App">
            <h3 style={{ marginLeft: '16px', marginTop: '16px' }}>You're offline at the moment.</h3>
            <p style={{ marginLeft: '16px' }}>Feel free to jot down your thoughts below.</p>
            
            <div style={{ margin: '16px', display: 'flex', flexDirection: 'column' }}>
                <textarea
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    placeholder="Write your entry here..."
                    rows={6}
                    style={{ width: '100%', backgroundColor: 'white', marginBottom: '8px' }}
                />
                <input
                    type="text"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    placeholder="Category"
                    style={{ width: '200px', marginBottom: '8px' }}
                />
                <button onClick={handleSubmit} style={{ width: '200px', height: '42px' }}>
                    Save Entry
                </button>
            </div>

            <div style={{ margin: '16px' }}>
                <h4>Stored Entries:</h4>
                {localEntries.length === 0 ? (
                    <p>No entries stored locally.</p>
                ) : (
                    localEntries.map((entry, index) => (
                        <div key={index} style={{ marginBottom: '16px' }}>
                            <strong>Category:</strong> {entry.category || 'No Category'}
                            <p>{entry.content}</p>
                            <small>Time: {new Date(entry.timestamp).toLocaleString()}</small>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}
  
export default OfflinePage;
