import React, { useState, useEffect, useRef } from 'react';
import { Form, Dropdown, Button } from 'react-bootstrap';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { CheckSquare, Square } from 'react-bootstrap-icons';

const Download = ({ user, db }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchUserCategories = async () => {
      try {
        if (user) {
          const userCategoriesQuery = query(
            collection(db, 'messages'),
            where('uid', '==', user.uid)
          );
          const userCategoriesSnapshot = await getDocs(userCategoriesQuery);
  
          const categories = [];
          userCategoriesSnapshot.forEach((doc) => {
            const data = doc.data();
            categories.push(data.category);
          });
  
          // Remove duplicate categories
          const uniqueCategories = Array.from(new Set(categories));
  
          // Sort categories alphabetically
          const sortedCategories = uniqueCategories.sort((a, b) => a.localeCompare(b));
  
          setCategories(sortedCategories);
        }
      } catch (error) {
        console.error('Error fetching user categories:', error);
      }
    };
  
    fetchUserCategories(); // Log here to see if this function is called
  }, [user, db]);

  const handleCategoryToggle = (category, event) => {
    event.stopPropagation(); // Prevent the event from propagating
    if (selectedCategories.includes(category)) {
      setSelectedCategories((prevCategories) =>
        prevCategories.filter((prevCategory) => prevCategory !== category)
      );
    } else {
      setSelectedCategories((prevCategories) => [...prevCategories, category]);
    }
    // Do not close the dropdown here
  };

  const handleToggle = () => setMenuOpen(!menuOpen);

  const handleSelectAll = () => {
    if (selectedCategories.length === categories.length) {
      setSelectedCategories([]);
    } else {
      setSelectedCategories(categories);
    }
  };

  const handleDeselectAll = () => {
    setSelectedCategories([]);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest('.dropdown-header') // Only close on clicks outside the dropdown header
    ) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const dropdownTitle =
    selectedCategories.length > 0
      ? `Categories (${selectedCategories.length})`
      : selectedCategories.join(', ');

  const checkmarkStyle = {
    color: 'rgb(105, 105, 105)',
    marginRight: '10px',
    cursor: 'pointer',
  };

  const handleDownload = async () => {
    try {
      const contentToDownload = await Promise.all(
        selectedCategories.map(async (category) => {
          const messagesQuery = query(
            collection(db, 'messages'),
            where('uid', '==', user.uid),
            where('category', '==', category)
          );
          const messagesSnapshot = await getDocs(messagesQuery);

          const messages = [];
          messagesSnapshot.forEach((doc) => {
            const data = doc.data();
            messages.push({
              category: data.category,
              content: data.content,
              timestamp: data.timestamp.toDate().toLocaleString(),
            });
          });

          return messages.map(
            (message) => `${message.category}, ${message.content}, ${message.timestamp}`
          );
        })
      );

      const contentString = contentToDownload.flat().join('\n');

      const blob = new Blob([contentString], { type: 'text/plain' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'downloaded_content.txt';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error('Error fetching messages for download:', error);
    }
  };

  return (
    <div>
      <Form>
        <Form.Group controlId="categorySelect">
          <div onClick={handleToggle} style={{ display: 'inline-block' }}>
            <Dropdown show={menuOpen} onToggle={handleToggle} ref={dropdownRef}>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                style={{
                  marginTop: '8px',
                  height: '42px',
                  backgroundColor: 'transparent',
                  borderColor: 'rgb(121, 84, 180)',
                  marginLeft: '-12px'
                }}
              >
                {selectedCategories.length === 0 ? 'Download entries' : dropdownTitle}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={selectedCategories.length > 0 ? handleDeselectAll : handleSelectAll}>
                  {selectedCategories.length > 0 ? 'Deselect All' : 'Select All'}
                </Dropdown.Item>
                <Dropdown.Divider />
                {categories.map((category) => (
                    <Dropdown.Item key={category}>
                        <span onClick={(event) => handleCategoryToggle(category, event)} style={checkmarkStyle}>
                        {selectedCategories.includes(category) ? (
                            <CheckSquare size={24} color={'rgb(121, 84, 180)'} />
                        ) : (
                            <Square size={24} />
                        )}
                        </span>
                        {category}
                    </Dropdown.Item>
                    ))}    
              </Dropdown.Menu>
            </Dropdown>
            <Button
                    variant="primary"
                    onClick={handleDownload}
                    style={{ width: '100%', marginTop: '12px',backgroundColor: 'rgb(121, 84, 180)', borderColor: 'rgb(121, 84, 180)' }}
                    disabled={selectedCategories.length === 0}
                  >
                    Download
                  </Button>
          </div>
        </Form.Group>
      </Form>
    </div>
  );
};

export default Download;
