import React, { useState, forwardRef } from 'react';
import 'material-icons/css/material-icons.css';
import './HorizontalCard.css';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import Dropdown from 'react-bootstrap/Dropdown';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';

const calculateTimeDifference = (timestamp) => {
  const now = new Date();
  const messageTime = timestamp.toDate();
  const timeDifference = now - messageTime;
  const secondsPassed = Math.floor(timeDifference / 1000);
  const minutesPassed = Math.floor(timeDifference / (1000 * 60));
  const hoursPassed = Math.floor(timeDifference / (1000 * 60 * 60));
  const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (secondsPassed < 60) {
    return `${secondsPassed}${secondsPassed === 1 ? 's' : 's'}`;
  } else if (minutesPassed < 60) {
    return `${minutesPassed}${minutesPassed === 1 ? 'm' : 'm'}`;
  } else if (hoursPassed < 24) {
    return `${hoursPassed}${hoursPassed === 1 ? 'h' : 'h'}`;
  } else {
    return `${daysPassed}${daysPassed === 1 ? 'd' : 'd'}`;
  }
};

const HorizontalCard = forwardRef((props, ref) => {
  const { timestamp, description, category, messageId, onDelete, onCardClick } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editedContent, setEditedContent] = useState(description);
  const [editedCategory, setEditedCategory] = useState(category);
  const [originalContent, setOriginalContent] = useState(description);
  const [originalCategory, setOriginalCategory] = useState(category);
  const [showCollectionModal, setShowCollectionModal] = useState(false);
  const [userCollections, setUserCollections] = useState([]);
  const [user, loading, error] = useAuthState(auth);
  const [selectedCollectionName, setSelectedCollectionName] = useState('');
  const [collectionName, setCollectionName] = useState('');
  const [hasAiResponse, setHasAiResponse] = useState(false);
  const [hasHallucinationResponse, setHasHallucinationResponse] = useState(false);
  const [hasComplexQueryResponse, setHasComplexQueryResponse] = useState(false);

  const [userPrompt, setUserPrompt] = useState('');
  const [hasPrompt, setHasPrompt] = useState(false);

  const cardBodyStyle = {
    padding: '16px !important',
    width: '400px !important',
    backgroundColor: '#353535',
    color: 'rgb(245, 245, 245)',
  };

  const cardStyle = {
    backgroundColor: '#353535',
    color: 'rgb(245, 245, 245)',
  };

  const handleEllipsisClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const resetEditedValues = () => {
    setEditedContent(originalContent);
    setEditedCategory(originalCategory);
  };

  const handleDelete = async () => {
    try {
      const messageRef = doc(db, 'messages', messageId);
      await deleteDoc(messageRef);
      setShowDeleteModal(false);
      onDelete(messageId);
    } catch (error) {
      console.error('Error deleting entry:', error);
    }
  };

  const handleEditClick = () => {
    if (!hasAiResponse) {
      setShowEditModal(true);
    }
  };

  const handleSaveEdit = async () => {
    try {
      const messageRef = doc(db, 'messages', messageId);
      await updateDoc(messageRef, {
        content: editedContent,
        category: editedCategory,
      });

      setShowEditModal(false);
    } catch (error) {
      console.error('Error updating entry:', error);
    }
  };

  const handleAddToCollection = async () => {
    const collectionValue = selectedCollectionName === 'None' ? '' : selectedCollectionName;
    try {
      const messageRef = doc(db, 'messages', messageId);
      await updateDoc(messageRef, {
        collectionName: collectionValue,
      });
      setCollectionName(collectionValue);
      setSelectedCollectionName('');
      setShowCollectionModal(false);
    } catch (error) {
      console.error('Error adding to collection:', error);
    }
  };

  const closeModal = () => {
    setShowDeleteModal(false);
    setShowEditModal(false);
  };

  function formatResponseText(text) {
    return text.split('\n').map((item, key) => (
      <span key={key}>
        {item}
        <br />
      </span>
    ));
  }

  const handleCardClick = () => {
    if (description.length > 128) {
      onCardClick({ timestamp, description, category, messageId });
    }
  };

  return (
    <div className="container card-container" id="cards">
      <div className="card mb-3" style={cardStyle}>
        <div className="row g-0">
          <div className="col-md-12">
            {(hasAiResponse || hasHallucinationResponse || hasComplexQueryResponse) && (
              <div style={{
                display: 'flex',
                backgroundColor: 'rgb(40,40,40)', justifyContent: 'center', borderBottom: 'solid 1px rgb(50,50,50)', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', padding: '12px', alignItems: 'center',
              }}>
                {hasAiResponse && hasPrompt && (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    animation: 'colorPulse 20s infinite',
                    backdropFilter: 'blur(40px)',
                    WebkitBackdropFilter: 'blur(40px)',
                    fontSize: '0.9em', backgroundColor: 'rgb(45,45,45)', padding: '8px', borderRadius: '300px'
                  }}>
                    {hasAiResponse && (
                      <i
                        className="material-icons"
                        style={{
                          alignItems: 'center',
                          margin: '0 auto',
                          color: 'hsla(0,0%,100%,.8)',
                        }}
                      >
                        quickreply
                      </i>
                    )}
                  </div>
                )}
                {hasHallucinationResponse && hasPrompt && (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    background: 'radial-gradient(circle, red, orange, yellow, green, blue, indigo, violet)',
                    backgroundSize: '300% 300%',
                    backdropFilter: 'blur(40px)',
                    WebkitBackdropFilter: 'blur(40px)',
                    animation: 'radialPulse 20s infinite',
                    color: 'rgb(180, 180, 180)', fontSize: '0.9em', padding: '8px', borderRadius: '200px'
                  }}>
                    {hasHallucinationResponse && (
                      <i
                        className="material-icons"
                        style={{
                          alignItems: 'center',
                          margin: '0 auto',
                          color: 'hsla(0,0%,100%,.8)',
                        }}
                      >
                        looks
                      </i>
                    )}
                    <strong></strong> {userPrompt}
                  </div>
                )}
                {hasComplexQueryResponse && (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    background: 'radial-gradient(circle, blue, indigo, violet)',
                    backgroundSize: '300% 300%',
                    backdropFilter: 'blur(40px)',
                    WebkitBackdropFilter: 'blur(40px)',
                    animation: 'radialPulse 10s infinite',
                    color: 'rgb(180, 180, 180)', fontSize: '0.9em', padding: '8px', borderRadius: '200px'
                  }}>
                    {hasComplexQueryResponse && (
                      <i
                        className="material-icons"
                        style={{
                          alignItems: 'center',
                          margin: '0 auto',
                          color: 'hsla(0,0%,100%,.8)',
                        }}
                      >
                        insights
                      </i>
                    )}
                    <strong></strong>
                  </div>
                )}
              </div>
            )}
            <div className="card-body" style={{ cardBodyStyle }} onClick={handleCardClick}>
              {hasComplexQueryResponse && (
                <div style={{ backgroundColor: 'rgb(45,45,45)', padding: '12px', borderRadius: '4px', marginBottom: '24px' }}>
                  {userPrompt}
                </div>
              )}
              {hasAiResponse && (
                <div style={{ backgroundColor: 'rgb(45,45,45)', padding: '12px', borderRadius: '4px', marginBottom: '24px' }}>
                  {userPrompt}
                </div>
              )}
              <div class="fade-container">
                <p
                  className="card-text"
                  style={{ textAlign: 'left', cursor: description.length > 165 ? 'pointer' : 'default' }}
                >
                  {formatResponseText(editedContent)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer d-flex justify-content-between align-items-center" style={{ backgroundColor: 'rgb(60, 60, 60)', height: '100px' }}>
          <div className="badge-container" style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginTop: '6px' }}>
            <div className="badge badge-first" style={{ backgroundColor: 'rgba(30,30,30,.5)', color: 'rgb(230, 230, 230)' }}>
              {editedCategory}
            </div>
            {collectionName && collectionName !== '' && collectionName !== 'None' && (
              <div className="badge badge-additional" style={{ backgroundColor: 'rgba(10,10,10,.5)', color: 'rgb(250, 250, 250)' }}>
                {collectionName}
              </div>
            )}
          </div>
          <div className="d-flex align-items-center">
            <small className="flex-grow-1" style={{ fontFamily: 'Heebo', opacity: 0.8, fontSize: '.75rem', marginLeft: '24px' }}>
              {calculateTimeDifference(timestamp)}
            </small>
            <div className="ellipsis-icon ml-2" style={{ marginLeft: '16px', cursor: 'pointer' }} onClick={handleEllipsisClick}>
              <i className="material-icons icon-half-size">more_vert</i>
            </div>
            <Dropdown show={isDropdownOpen} id="contentDropdown" className="ml-2" align="start" onToggle={handleEllipsisClick}>
              <Dropdown.Menu class="custom-dropdown-menu">
                <Dropdown.Item onClick={() => setShowCollectionModal(true)}>Select notebook</Dropdown.Item>
                {!hasAiResponse && !hasComplexQueryResponse && !hasHallucinationResponse && (
                  <Dropdown.Item onClick={handleEditClick}>Edit</Dropdown.Item>
                )}
                <Dropdown.Item onClick={() => setShowDeleteModal(true)}>Delete</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <div className="modal">
          <div className="modal-content" style={{ padding: '0px', maxWidth: '400px' }}>
            <div className="modal-header">
              <p style={{ color: 'rgb(200, 200, 200)' }}>
                <b>Delete Entry</b>
              </p>
              <div className="modalClose" id="close" onClick={closeModal}>
                <i className="material-icons" style={{ color: 'rgb(100, 100, 100)' }}>
                  close
                </i>
              </div>
            </div>
            <div id="modalContentWrapper">
              <p id="modalCopy">Are you sure you want to delete this entry?</p>
              <button id="yes" onClick={handleDelete}>
                Yes
              </button>
              <button id="no" onClick={() => setShowDeleteModal(false)} style={{
                border: 'solid rgb(121, 84, 180)',
                color: 'rgb(250,250,250)',
                backgroundColor: 'transparent',
                width: '30vw',
                margin: '10px',
                maxWidth: '300px',
                padding: '11px 16px',
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = 'rgb(90, 59, 138)';
                  e.target.style.borderColor = 'rgb(90, 59, 138)';
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = 'transparent';
                  e.target.style.border = 'solid rgb(121, 84, 180)';
                }}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {showCollectionModal && (
        <div className="modal" style={{ borderBottomLeftRadius: '8px', }}>
          <div className="modal-content" style={{ padding: '0px', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', maxWidth: '400px', margin: 'auto' }}>
            <div className="modal-header">
              <p style={{ color: 'rgb(200, 200, 200)' }}>
                <b>Select Notebook</b>
              </p>
              <div className="modalClose" onClick={() => setShowCollectionModal(false)}>
                <i className="material-icons" style={{ color: 'rgb(100, 100, 100)', cursor: 'pointer' }}>
                  close
                </i>
              </div>
            </div>
            <div style={{ padding: '16px', backgroundColor: 'rgb(53,53,53)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', }}>
              <Dropdown onSelect={(eventKey) => {
                if (eventKey === "none") {
                  setSelectedCollectionName('None');
                } else {
                  const selectedCollection = userCollections.find((collection) => collection.id === eventKey);
                  setSelectedCollectionName(selectedCollection ? selectedCollection.name : '');
                }
              }}>
                <Dropdown.Toggle id="collection-dropdown" variant="secondary" style={{ width: '100%', backgroundColor: 'rgb(75,75,75)', borderColor: 'rgb(75,75,75)', color: 'rgb(200,200,200)', }}>
                  {selectedCollectionName || "Select a collection"}
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ backgroundColor: 'rgb(75,75,75)', color: 'rgb(200,200,200)', maxHeight: '200px', overflowY: 'auto' }}>
                  <Dropdown.Item eventKey="none" style={{ color: 'rgb(200,200,200)', fontWeight: '300', width: '260px' }}>
                    None
                  </Dropdown.Item>
                  {userCollections.map((collection) => (
                    <Dropdown.Item key={collection.id} eventKey={collection.id} style={{ color: 'rgb(200,200,200)', fontWeight: '300', width: '260px' }}>
                      {collection.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <button onClick={handleAddToCollection} style={{ backgroundColor: '#7954B4', marginTop: '12px', marginBottom: '4px', color: 'white', border: 'none', padding: '8px 16px', float: 'right', cursor: 'pointer' }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = 'rgb(90, 59, 138)';
                  e.target.style.borderColor = 'rgb(90, 59, 138)';
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = 'rgb(121, 84, 180)';
                  e.target.style.borderColor = 'rgb(121, 84, 180)';
                }}
              >
                Confirm selection
              </button>
            </div>
          </div>
        </div>
      )}
      {showEditModal && (
        <div className="modal">
          <div className="modal-content" style={{ padding: '0px', maxWidth: '400px', margin: 'auto' }}>
            <div className="modal-header">
              <p style={{ color: 'rgb(200, 200, 200)' }}>
                <b>Edit Entry</b>
              </p>
              <div className="modalClose" style={{ float: 'right' }} id="close" onClick={closeModal}>
                <i className="material-icons" style={{ color: 'rgb(100, 100, 100)', cursor: 'pointer' }}>
                  close
                </i>
              </div>
            </div>
            <div id="modalContentWrapper" style={{ padding: '16px' }}>
              <label htmlFor="editedContent" style={{ float: 'left', color: 'rgb(245, 245, 245)', marginBottom: '8px' }}>
                Content:
              </label>
              <textarea
                id="editedContent"
                value={editedContent}
                onChange={(e) => setEditedContent(e.target.value)}
                style={{ width: '100%', color: 'rgb(200,200,200)', backgroundColor: 'rgb(75,75,75)', padding: '8px', marginBottom: '16px', resize: 'vertical' }}
              />
              <label htmlFor="editedCategory" style={{ float: 'left', color: 'rgb(245, 245, 245)', marginBottom: '8px' }}>
                Category:
              </label>
              <input
                type="text"
                id="editedCategory"
                value={editedCategory}
                onChange={(e) => setEditedCategory(e.target.value)}
                style={{ width: '100%', color: 'rgb(200,200,200)', backgroundColor: 'rgb(75,75,75)', padding: '8px', marginBottom: '16px' }}
              />
              <button id="save" onClick={handleSaveEdit} onMouseEnter={(e) => {
                e.target.style.backgroundColor = 'rgb(90, 59, 138)';
                e.target.style.borderColor = 'rgb(90, 59, 138)';
              }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = 'rgb(121, 84, 180)';
                  e.target.style.borderColor = 'rgb(121, 84, 180)';
                }} style={{ backgroundColor: '#7954B4', color: 'white', border: 'none', padding: '8px 16px', marginRight: '8px', cursor: 'pointer' }}>
                Save
              </button>
              <button
                id="cancel"
                onClick={() => {
                  closeModal();
                  resetEditedValues();
                }}
                style={{
                  border: 'solid rgb(121, 84, 180)',
                  color: 'rgb(250,250,250)',
                  backgroundColor: 'transparent',
                  padding: '7px 16px',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = 'rgb(90, 59, 138)';
                  e.target.style.borderColor = 'rgb(90, 59, 138)';
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = 'transparent';
                  e.target.style.border = 'solid rgb(121, 84, 180)';
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default HorizontalCard;
