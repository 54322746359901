import React, { useEffect, useState } from 'react';
import { auth } from '../firebase'; // Ensure you import the auth instance from your Firebase configuration
import { onAuthStateChanged } from 'firebase/auth';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase'; // Assuming this is your Firebase configuration file
import 'material-icons/css/material-icons.css';

const OfflineEntriesModal = ({ offlineEntries, onSubmit, onClose }) => {
    const [user, setUser] = useState(null);
  
    useEffect(() => {
      // Listen for changes in the authentication state
      const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser); // Set the user in state
      });
  
      return () => unsubscribe(); // Unsubscribe when the component unmounts
    }, []);
  
    const handleSubmit = async () => {
      const messagesCollection = collection(db, 'messages');
  
      for (const entry of offlineEntries) {
        if (user) { // Ensure user is available before submitting
          await addDoc(messagesCollection, {
            content: entry.content,
            category: entry.category,
            uid: user.uid, // Use the user's UID
            username: user.displayName, // Use the user's display name
            timestamp: new Date(entry.timestamp),
            order: null,
          });
        }
      }
  
      onSubmit(); // Callback to clear offline entries and close the modal
    };

    const handleClearEntries = () => {
        // Clear the offline entries from local storage
        localStorage.removeItem('offlineEntries'); // Replace 'offlineEntries' with the actual key used for storing entries
        onClose(); // Close the modal
      };

      const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    return (
        <div className="modal show" style={{ display: 'block' }}> {/* Add show class and inline style for display */}
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" style={{marginTop:'8px', marginBottom:'8px'}}>Submit Offline Entries</h5>
                <div
                    onClick={onClose}
                    style={{
                    position: 'absolute', // Position it relative to the modal content
                    top: '18px', // Align with padding
                    right: '24px', // Align with padding
                    cursor: 'pointer',
                    fontSize: '24px',
                    color: 'rgb(120,120,120)',
                    }}
                >
                    <i className="material-icons">close</i> {/* Assuming you are using Material Icons */}
                </div>
              </div>
              <div className="modal-body" style={{ backgroundColor: 'rgb(45,45,45)', padding: '20px' }}>
                        {offlineEntries.map((entry, index) => (
                            <div key={index} style={{ marginBottom: '15px', borderBottom: '1px solid rgb(70,70,70)', paddingBottom: '10px' }}>
                                <p style={{ color: 'white', fontSize: '16px', margin: '0 0 10px 0' }}>{entry.content}</p>
                                <small style={{ color: 'rgb(180,180,180)' }}>Category: {entry.category} <br></br>{formatDate(entry.timestamp)}</small>
                            </div>
                        ))}
                    </div>
              <div className="modal-footer" style={{backgroundColor:'rgb(45,45,45', borderTop: 'none'}}>
                <button type="button" className="btn btn-primary" style={{backgroundColor:'rgb(121, 84, 180)'}} onClick={handleSubmit}>Submit Entries</button>
                <button type="button" className="btn btn-secondary" style={{backgroundColor:'transparent', border:'solid rgb(121, 84, 180)' }} onClick={handleClearEntries}>Clear Entries</button>
              </div>
            </div>
          </div>
        </div>
      );
    };

export default OfflineEntriesModal;
