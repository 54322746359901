import React, { useEffect } from 'react';

const AudioVisualizer = ({ audioData, setAudioData, isRecording }) => {
    useEffect(() => {
      let audioContext;
      let analyser;
      let rafId;

      const startVisualization = () => {
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
        analyser = audioContext.createAnalyser();
  
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then(stream => {
            const microphone = audioContext.createMediaStreamSource(stream);
            microphone.connect(analyser);
            analyser.fftSize = 32;
            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);
  
            const tick = () => {
              analyser.getByteFrequencyData(dataArray);
              // Normalize the data
              const maxAmplitude = Math.max(...dataArray);
              const normalizedData = dataArray.map(value => (value / maxAmplitude) * 64); // Scale to fit within 80px
              setAudioData([...normalizedData]);
              rafId = requestAnimationFrame(tick);
            };
  
            tick();
          })
          .catch(err => console.error('Error accessing media devices.', err));
      };
  
      if (isRecording) {
        startVisualization();
      } else {
        if (audioContext) {
          audioContext.close();
        }
        if (rafId) {
          cancelAnimationFrame(rafId);
        }
        setAudioData(new Uint8Array(0)); // Clear the visualizer
      }
  
      return () => {
        if (audioContext) {
          audioContext.close();
        }
        if (rafId) {
          cancelAnimationFrame(rafId);
        }
      };
    }, [isRecording, setAudioData]);

    return (
      <div style={{ marginTop: '2px' }}>
        {audioData.map((value, index) => (
          <div 
            key={index} 
            style={{
              height: `${value}px`, 
              marginRight: '2px', 
              backgroundColor: 'rgb(200, 200, 200)', 
              width: '5px', 
              display: 'inline-block'
            }} 
          />
        ))}
      </div>
    );
};

export default AudioVisualizer;
