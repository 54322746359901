import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { signOut } from 'firebase/auth';
import { auth, db } from '../firebase';
import { collection, getDocs, where, query, orderBy, limit, startAfter, doc, deleteDoc } from 'firebase/firestore';
import HorizontalCard from './HorizontalCard';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './Dashboard.css';
import Dropdown from 'react-bootstrap/Dropdown';
import Navigation from './Navigation';
import Download from './Download';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Modal from 'react-bootstrap/Modal';
import 'material-icons/css/material-icons.css';
import Button from 'react-bootstrap/Button';

const Dashboard = () => {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [messages, setMessages] = useState([]);
  const [deletedCardIds, setDeletedCardIds] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [sortByCategory, setSortByCategory] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [allEntriesLoaded, setAllEntriesLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const observerRef = useRef();
  const [ActionText, setActionText] = useState('');
  const [formattedResponse, setFormattedResponse] = useState('');
  const [containerHeight, setContainerHeight] = useState('auto');

  const initialFetchRef = useRef(false);
  const fetchingRef = useRef(false);

  useEffect(() => {
    if (loading) return;
    if (!user) {
      navigate('/');
      return;
    }
    if (!initialFetchRef.current) {
      fetchInitialData();
      initialFetchRef.current = true;
    }

    return () => {
      setMessages([]);
      setLastVisible(null);
      setAllEntriesLoaded(false);
    };
  }, [user, loading, navigate]);

  const handleActionPrompt = async () => {
    const functions = getFunctions();
    const generateActionPrompt = httpsCallable(functions, 'generateAction');

    try {
      const response = await generateActionPrompt();
      if (response && response.data && response.data.prompt) {
        const action = response.data.prompt.replace(/['"]+/g, '');
        const formattedText = formatResponseText(action);
        setFormattedResponse(formattedText);
        setActionText(formattedText);
        setContainerHeight(`${Math.max(action.length * 0.8, 80)}px`);
      } else {
        console.error('No valid prompt response.');
      }
    } catch (error) {
      console.error('Error generating prompt:', error);
    }
  };

  useEffect(() => {
    handleActionPrompt();
  }, []);

  const fetchInitialData = async () => {
    setMessages([]);
    setLastVisible(null);
    setAllEntriesLoaded(false);
    await fetchPaginatedData();
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting && !allEntriesLoaded && !fetchingRef.current) {
          fetchPaginatedData();
        }
      },
      { threshold: 1.0 }
    );

    const currentObserverRef = observerRef.current;
    if (currentObserverRef) {
      observer.observe(currentObserverRef);
    }

    return () => {
      if (currentObserverRef) {
        observer.unobserve(currentObserverRef);
      }
      observer.disconnect();
    };
  }, [lastVisible, allEntriesLoaded]);

  const fetchPaginatedData = async () => {
    if (!user || allEntriesLoaded || fetchingRef.current) return;

    fetchingRef.current = true;

    let queryRef = query(collection(db, 'messages'), where('uid', '==', user.uid), orderBy('timestamp', 'desc'), limit(10));
    if (lastVisible) {
      queryRef = query(queryRef, startAfter(lastVisible));
    }

    const querySnapshot = await getDocs(queryRef);
    const newMessages = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    const uniqueNewMessages = newMessages.filter(newMessage => !messages.some(message => message.id === newMessage.id));

    if (uniqueNewMessages.length > 0) {
      setMessages(messages => [...messages, ...uniqueNewMessages]);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    } else if (newMessages.length < 10) {
      setAllEntriesLoaded(true);
    }

    fetchingRef.current = false;
  };

  // const handleDelete = async (messageId) => {
  //   try {
  //     const messageRef = doc(db, 'messages', messageId);
  //     await deleteDoc(messageRef);
  //     setMessages(messages => messages.filter(message => message.id !== messageId));
  //   } catch (error) {
  //     console.error('Error deleting entry:', error);
  //   }
  // };

  const handleDelete = async (messageId) => {
    try {
      const messageRef = doc(db, 'messages', messageId);
      await deleteDoc(messageRef);
      setMessages(messages => messages.filter(message => message.id !== messageId));
    } catch (error) {
      console.error('Error deleting entry:', error);
    }
  };
  

  const handleSearch = async (searchTerm) => {
    setSearchQuery(searchTerm);

    if (searchTerm === '') {
      setSelectedMessage(null);
      setIsSearching(false);
      setSearchResults([]);
      return;
    }

    setIsSearching(true);
    const filteredMessages = messages.filter(message =>
      message.content.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredMessages);
  };

  const onMessageSelected = (selected) => {
    if (selected.length > 0) {
      setSelectedMessage(selected[0]);
      setIsSearching(true);
    } else {
      setSelectedMessage(null);
      setIsSearching(false);
    }
  };

  const sortByTimestamp = () => {
    const sortedMessages = [...messages].sort((a, b) => b.timestamp - a.timestamp);
    setMessages(sortedMessages);
  };

  const sortByCategoryFunction = () => {
    const sortedMessages = [...messages].sort((a, b) => a.category.localeCompare(b.category));
    setMessages(sortedMessages);
  };

  const handleSortChange = (sortType) => {
    setSortByCategory(sortType === 'category');
    if (sortType === 'category') {
      sortByCategoryFunction();
    } else {
      sortByTimestamp();
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Sign-out error:', error);
    }
  };

  const handleToggleSidebar = () => setShowSidebar(!showSidebar);

  const handleCardClick = (cardData) => {
    setModalContent(cardData);
    setShowModal(true);
  };

  function formatResponseText(text) {
    if (!text) {
      return null;
    }
    return text.split('\n').map((item, key) => (
      <span key={key}>
        {item}
        <br />
      </span>
    ));
  }

  return (
    !user ? <div></div>
      : (
        <div className="dashboard-header" style={{ background: 'rgb(20, 20, 20)' }}>
          {!isCameraActive && <Navigation navigate={navigate} db={db} user={user} handleLogout={handleLogout} />}

          <div className="content-container" style={{ marginLeft: '8px', marginRight: '8px', display: 'flex', flexDirection: 'column' }}>

            <div className="search-bar-container" style={{ marginTop: '16px', marginBottom: '12px', width: '100%' }}>
              <div className="search-div" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'left', marginBottom: '12px' }}>
                <Typeahead
                  id="search-bar"
                  style={{ height: '43px', color: 'rgb(80,80,80)' }}
                  onChange={onMessageSelected}
                  onInputChange={(value) => handleSearch(value)}
                  options={searchResults}
                  labelKey="content"
                  placeholder="Search entries..."
                  minLength={2}
                />

                <div id="row">
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" style={{ backgroundColor: 'rgb(40, 40, 40)', borderColor: 'rgb(60, 60, 60)', marginLeft: '8px' }}>
                      {sortByCategory ? 'Sort by Category' : 'Sort by Timestamp'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleSortChange('timestamp')}>Sort by Timestamp</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleSortChange('category')}>Sort by Category</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <button className="btn btn-dark" id="downloadBtn" style={{ backgroundColor: 'rgb(45,45,45)', marginLeft: '22px', marginRight: '8px', height: '43px' }} onClick={handleToggleSidebar}>
                    <i className="material-icons icon" style={{ color: 'rgb(200,200,200)' }}>download_for_offline</i>
                  </button>
                </div>

                <Offcanvas show={showSidebar} style={{ backgroundColor: 'rgb(40,40,40)' }} onHide={() => setShowSidebar(false)} placement="end">
                  <Offcanvas.Header closeButton style={{ backgroundColor: 'rgb(60,60,60)' }}>
                    <Offcanvas.Title style={{ color: 'white' }}>Download Entries</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Download db={db} user={user} />
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
            </div>
            <div className="main-content" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

              <div className="card-container" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', marginRight: '20px' }}>
                {isSearching && selectedMessage ? (
                  <div key={`search-${selectedMessage.id}`} style={{ maxWidth: '528px', display: 'block' }}>
                    <HorizontalCard
                      className="horizontal-card"
                      data-card-id={selectedMessage.id}
                      timestamp={selectedMessage.timestamp}
                      description={selectedMessage.content}
                      category={selectedMessage.category}
                      messageId={selectedMessage.id}
                      onDelete={handleDelete}
                      onCardClick={handleCardClick}
                    />
                  </div>
                ) : isSearching ? (
                  searchResults.map(message => (
                    <div key={`search-${message.id}`} style={{ maxWidth: '528px', display: 'block' }}>
                      <HorizontalCard
                        className="horizontal-card"
                        data-card-id={message.id}
                        timestamp={message.timestamp}
                        description={message.content}
                        category={message.category}
                        messageId={message.id}
                        onDelete={handleDelete}
                        onCardClick={handleCardClick}
                      />
                    </div>
                  ))
                ) : (
                  messages.map((message, index) => (
                    <div key={message.id} style={{ maxWidth: '528px', display: 'block' }}>
                      <HorizontalCard
                        className="horizontal-card"
                        data-card-id={message.id}
                        timestamp={message.timestamp}
                        description={message.content}
                        category={message.category}
                        messageId={message.id}
                        onDelete={handleDelete}
                        onCardClick={handleCardClick}
                      />
                    </div>
                  ))
                )}

                {allEntriesLoaded && !isSearching && <div style={{ margin: '10px 0', textAlign: 'center' }}>You've reached the end of your entries.</div>}
                {!allEntriesLoaded && <div ref={observerRef} style={{ height: '20px', margin: '10px 0' }} />}
              </div>

            </div>
          </div>
          <Modal id="variantModal" show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header>
              <Button variant="link" onClick={() => setShowModal(false)} style={{ textDecoration: 'none' }}>
                <i className="material-icons">open_in_full</i>
              </Button>
            </Modal.Header>
            <Modal.Body>
              <div>{formatResponseText(modalContent.description)}</div>
            </Modal.Body>
          </Modal>
        </div>
      )
  );
};

export default Dashboard;
