// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore'; // Add this import for Firestore
import { getFunctions } from 'firebase/functions';


const firebaseConfig = {
    apiKey: "AIzaSyBFnpdbk1LnKBqf19cVwUNvUZg_MCX99Z4",
    authDomain: "descartes-2e927.firebaseapp.com",
    projectId: "descartes-2e927",
    storageBucket: "descartes-2e927.appspot.com",
    messagingSenderId: "672104436600",
    appId: "1:672104436600:web:af65436750682e38f5ee8d",
    measurementId: "G-6D16XYPLQJ",
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const db = getFirestore(app); // Export Firestore as 'db'
export const auth = getAuth(app);
export const functions = getFunctions(app); // Make sure you import getFunctions correctly
export default app;