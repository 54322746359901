import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navigation from './Navigation';
import { collection, getDocs, query, where, orderBy, limit, addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Button } from 'react-bootstrap';

const ProfilePage = ({ handleLogout }) => {
    const [collectionsData, setCollectionsData] = useState([]);
    const [biography, setBiography] = useState("");
    const [username, setUsername] = useState("");
    const { username: urlUsername } = useParams();
    const [user, loading] = useAuthState(auth);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchUserDataByUsername(username) {
            const userRef = collection(db, "users");
            const q = query(userRef, where("username", "==", username));
            const userSnapshot = await getDocs(q);
            if (!userSnapshot.empty) {
                const userData = userSnapshot.docs[0].data();
                setUsername(userData.username || "No username");
                setBiography(userData.biography || "No biography provided.");
                fetchCollections(userData.UID);
            }
        }

        if (urlUsername) {
            fetchUserDataByUsername(urlUsername);
        }
    }, [urlUsername]);

    async function fetchCollections(uid) {
        setIsLoading(true);
        const collectionsRef = collection(db, 'collections');
        const publicCollectionsQuery = query(collectionsRef, where("uid", "==", uid), where("isPublic", "==", true));
        const collectionsSnapshot = await getDocs(publicCollectionsQuery);
        const collectionsDataTemp = [];

        for (const doc of collectionsSnapshot.docs) {
            const collectionData = doc.data();
            const messagesRef = collection(db, 'messages');
            const totalEntriesQuery = query(messagesRef, where("collectionName", "==", collectionData.name), where("uid", "==", uid));
            const messagesQuery = query(messagesRef, where("collectionName", "==", collectionData.name), where("uid", "==", uid), orderBy("timestamp", "desc"), limit(5));
            const totalEntriesSnapshot = await getDocs(totalEntriesQuery);
            const messagesSnapshot = await getDocs(messagesQuery);

            collectionsDataTemp.push({
                id: doc.id,
                name: collectionData.name,
                isPublic: collectionData.isPublic,
                uid: collectionData.uid,
                messages: messagesSnapshot.docs.map(messageDoc => messageDoc.data().content),
                totalEntries: totalEntriesSnapshot.size
            });
        }
        setCollectionsData(collectionsDataTemp);
        setIsLoading(false);
    }

    const addCollectionToUser = async (collectionId) => {
        if (!user) {
            console.log("No user logged in");
            return; // Ensure there is a logged-in user
        }
    
        try {
            const collectionRef = doc(db, 'collections', collectionId);
            const collectionDoc = await getDoc(collectionRef);
    
            if (collectionDoc.exists()) {
                const collectionData = collectionDoc.data();
    
                if (user.uid !== collectionData.uid) {
                    // Convert collaborators to a Set to ensure unique entries
                    const collaborators = new Set(collectionData.collaborators || []);
    
                    // Check if the user is already a collaborator
                    if (collaborators.has(user.uid)) {
                        alert(`You are already subscribed to the collection '${collectionData.name}'.`);
                        return; // Exit the function to prevent further execution
                    }
    
                    // Add user UID to the collaborators set and update the document
                    collaborators.add(user.uid);
    
                    await updateDoc(collectionRef, {
                        collaborators: Array.from(collaborators)
                    });
    
                    alert(`Added ${collectionData.name} to your collections as a subscriber.`);
                } else {
                    alert('You are the original creator of this collection.');
                }
            } else {
                console.log('Collection does not exist.');
                alert('Collection not found.');
            }
        } catch (error) {
            console.error('Error adding collection to user:', error);
            alert(`Failed to add collection. Error: ${error.message}`);
        }
    };

    if (loading || isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Navigation handleLogout={handleLogout} />
            <h1 style={{marginLeft: '16px', marginTop: '32px', maxWidth: '720px'}}>{username}</h1>
            <p style={{marginLeft: '16px', maxWidth: '420px', marginRight: '16px'}}>{biography}</p>
            <div className="cosine-sim-container d-flex flex-wrap justify-content-start" style={{ margin: '8px' }}>
                {collectionsData.length > 0 ? collectionsData.map((collection, index) => (
                    <div key={index} className="card m-2" style={{ backgroundColor: 'rgb(53, 53, 53)', width: '400px', color: 'rgb(245, 245, 245)' }}>
                        <div className="card-body">
                            <h5 className="card-title">{collection.name} - Entries: {collection.totalEntries}</h5>
                            {user && user.uid !== collection.uid && 
                                <Button onClick={() => addCollectionToUser(collection.id)} style={{ margin: '10px', backgroundColor: 'rgb(121, 84, 180)', color: 'white', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer' }}>
                                    Subscribe
                                </Button>
                            }
                            <ul>
                                {collection.messages.map((message, messageIndex) => (
                                    <li key={messageIndex}>{message}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )) : <p style={{marginLeft: '8px'}}>No public collections found.</p>}
            </div>
        </div>
    );
};

export default ProfilePage;
