import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, db } from '../firebase';
import Dropdown from 'react-bootstrap/Dropdown';
import logo from '../images/logo192.png';
import 'material-icons/css/material-icons.css';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUsername] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate('/');
      } else {
        console.log(user.uid); // Logging user UID
        await fetchUsername(user.uid);
      }
    });

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      unsubscribe(); // Clean up subscription
      window.removeEventListener('resize', handleResize);
    };
  }, [navigate]);

  const fetchUsername = async (uid) => {
    try {
      const userQuery = query(collection(db, 'users'), where('UID', '==', uid));
      const querySnapshot = await getDocs(userQuery);

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        setUsername(userData.username);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out the user
      navigate('/'); // Navigate back to the home page after logout
      console.log('User logged out');
    } catch (error) {
      console.error('Logout Error:', error);
    }
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <nav className="d-flex justify-content-between" style={{
      background: 'rgb(20,20,20)',
      borderBottomWidth: 'thin',
      borderBottom: 'solid #353535',
      height: '72px',
    }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={logo} alt="Logo" style={{ marginLeft: '16px', width: '36px', height: '36px' }} />
      </div>

      <div className="d-flex justify-content-center align-items-center" style={{ flexGrow: 1, gap: '24px', marginTop: '8px', marginLeft: windowWidth < 768 ? '24px' : '0px' }}>
        {[
          { icon: 'add_circle_outline', title: 'Create', path: '/create-entry' },
          { icon: 'dashboard', title: 'Notes', path: '/dashboard' },
          { icon: 'library_books', title: 'Notebooks', path: '/notebooks' },
          // { icon: 'chat', title: 'Chat', path: '/chat' },
          // { icon: 'analytics', title: 'Analyze', path: '/contentmap' },
          // { icon: 'explore', title: 'Explore', path: '/explore' }
        ].filter(item => !(windowWidth < 768 && (item.icon === 'explore' || item.icon === 'analytics'))).map(item => (
          <div key={item.path} onClick={() => navigate(item.path)} className="nav-item" style={{
            cursor: 'pointer',
            color: isActive(item.path) ? 'white' : 'rgb(180,180,180)',
            textDecoration: isActive(item.path) ? 'underline' : 'none',
            textAlign: 'center'
          }}>
            <i className="material-icons">{item.icon}</i>
            <div style={{ fontSize: '12px', display: windowWidth < 768 ? 'none' : 'block' }}>{item.title}</div>
          </div>
        ))}
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic-button" style={{
            marginLeft: '0px', marginRight: '16px',
            backgroundColor: windowWidth < 768 ? 'transparent' : 'rgb(40, 40, 40)',
            borderColor: windowWidth < 768 ? 'transparent' : 'rgb(60, 60, 60)',
            display: 'flex', alignItems: 'center',
            color: windowWidth < 768 ? 'rgb(180,180,180)' : 'inherit'
          }}>
            {windowWidth <= 768 ? <i style={{ color: 'rgb(180,180,180)' }} className="material-icons">more_vert</i> : 'You'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {windowWidth <= 768 && (
              <>
                <Dropdown.Item onClick={() => navigate('/explore')}>
                  Explore
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigate('/contentmap')}>
                  Analyze
                </Dropdown.Item>
              </>
            )}
            <Dropdown.Item onClick={() => navigate(`/geist/${username}`)}>
              Your profile
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={handleLogout}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </nav>
  );
};

export default Navigation;
